import { MapType, FeederLinesType, SelectedScenarioLineDataType } from "../types/dataTypes"

export function mapGetLayerExists(map: MapType, layerId: string) {
    return map.current?.getLayer(layerId);
}

export function mapGetSourceExists(map: MapType, layerId: string) {
    return map.current?.getSource(layerId);
}

// VISIBILITY

export function mapVisibilityNone(map: MapType, layerId: string) {
    map.current?.setLayoutProperty(layerId, 'visibility', 'none');
}

export function mapVisibilityVisible(map: MapType, layerId: string) {
    map.current?.setLayoutProperty(layerId, 'visibility', 'visible');
}

// FILTER FUNCTIONS

// export function mapFilterFeederIdAndScenario(map: MapType, feederId: string | number, scenarioId: string | number) {
//     if (!map.current) return
//     map.current.setFilter(LAYER_IDS.FEEDER_DETAILED, ['all', ['==', ['get', 'feeder_id'], Number(feederId)], ['==', ['get', 'scenario_id'], Number(scenarioId)]]);
// }

// CURSOR FUNCTIONS

export function mapCursorPointer(map: MapType) {
    if (!map.current) return
    map.current.getCanvas().style.cursor = 'pointer';
}

export function mapCursorNormal(map: MapType) {
    if (!map.current) return
    map.current.getCanvas().style.cursor = '';
}

// REMOVE LAYER AND SOURCE

export function mapRemoveLayerSource(map: MapType, layerIdArray: string[]) {
    if (!map.current) return
    layerIdArray.map((layerId) => function () {
        if (mapGetLayerExists(map, layerId)) { map.current?.removeLayer(layerId) }
        if (mapGetSourceExists(map, layerId)) { map.current?.removeSource(layerId) }
    });
}

export function extractLineScenarioValues(geodata: FeederLinesType, scenariodata: SelectedScenarioLineDataType) {
    const feederLinesGeodataWithValues = geodata?.features.map(lines => {
      const scenarioDataForLine = scenariodata?.filter(scenData => scenData.line_id === lines.properties.line_id)[0];
      return (
        {
          ...lines,
          properties: {
            line_id: lines.properties.line_id,
            len: lines.properties.len,
            conductor: lines.properties.conductor,
            losses: Number(scenarioDataForLine?.losses),
            voltage_drop: Number(scenarioDataForLine?.voltage_drop),
          }
        })
    }
    );
    return feederLinesGeodataWithValues;
  }

