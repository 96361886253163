import { LOSSES_LEGEND, VOLTAGE_LEGEND } from './consts';

export const LAYER_IDS = { 
    FEEDER_DETAILED: 'feeders', 
    FEEDER_LINE_SELECTED: "selectedFeeder", 
    SUBSTATIIONSALL: 'substationsAll',
    TRANSMISSIONSLINES: 'transmissionLines',
    TRANSFORMERS: 'feederTransformers',
    TRANSFORMERS_MODELLED: 'feederTransformersModelled',
}

export const MAP_BOUNDS: maplibregl.LngLatBoundsLike = [[74.5, 32.4],[71.3, 34.5]];
export const MAP_CENTRE: maplibregl.LngLatLike = [73.7, 33.6];  
export const MAP_PADDING: maplibregl.RequireAtLeastOne<maplibregl.PaddingOptions> = { bottom: 20, top: 100, left: 140, right: 10 };

export const GEOSERVER = process.env.REACT_APP_GEOSERVER;
export const MVT = {
    start: `${GEOSERVER}/gwc/service/wmts?request=GetTile&service=WMTS&LAYER=sofipdb:`,
    end: '&FORMAT=application/vnd.mapbox-vector-tile&TILEMATRIXSET=EPSG:3857&TILEMATRIX=EPSG:3857:{z}&TILECOL={x}&TILEROW={y}',
};

export const BASE_LAYERS = [
    { text: 'Road / Terrain', id: 'osm' }, //first position is the activated layer
    { text: 'Satellite', id: 'satellite' },
];

export const OVERLAY_LAYERS = [
    { text: 'High-voltage grid', id: LAYER_IDS.TRANSMISSIONSLINES },
];

export const PAINT_STYLES = {
    SELECTED_GRID_SEGMENT: {
        "line-width": 9,
        "line-color": '#000',
    },
    FEEDERS_LOSS: {
        "line-color": [
            'case',
            ['>=', ['to-number', ['get', 'losses']], LOSSES_LEGEND[3].min / 1000],
            `${LOSSES_LEGEND[3].color}`,
            ['>=', ['to-number', ['get', 'losses']], LOSSES_LEGEND[2].min / 1000],
            `${LOSSES_LEGEND[2].color}`,
            ['>=', ['to-number', ['get', 'losses']], LOSSES_LEGEND[1].min / 1000],
            `${LOSSES_LEGEND[1].color}`,
            ['>=', ['to-number', ['get', 'losses']], LOSSES_LEGEND[0].min / 1000],
            `${LOSSES_LEGEND[0].color}`,
            `#000`,
        ],
        "line-width": 4,
    },
    FEEDERS_VOLTAGE: {
        "line-color": [
            'case',
            ['>=', ['to-number', ['get', 'voltage_drop']], VOLTAGE_LEGEND[3].min],
            `${VOLTAGE_LEGEND[3].color}`,
            ['>=', ['to-number', ['get', 'voltage_drop']], VOLTAGE_LEGEND[2].min],
            `${VOLTAGE_LEGEND[2].color}`,
            ['>=', ['to-number', ['get', 'voltage_drop']], VOLTAGE_LEGEND[1].min],
            `${VOLTAGE_LEGEND[1].color}`,
            ['>=', ['to-number', ['get', 'voltage_drop']], VOLTAGE_LEGEND[0].min],
            `${VOLTAGE_LEGEND[0].color}`,
            `#000`,
        ],
        "line-width": 4,
    }
};

export const STYLE: maplibregl.StyleSpecification = {
    version: 8,
    sources: {
        osm: {
            type: "raster",
            tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution:
                '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
            maxzoom: 19,
        },
        osmhot: {
            type: "raster",
            tiles: ["https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution:
                '&copy; <a href="httsp://osm.org/copyright">OpenStreetMap</a> contributors. Tiles style by <a href="https://www.hotosm.org/">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://www.openstreetmap.fr/">OpenStreetMap France</a>',
            maxzoom: 19,
        },
        satellite: {
            type: "raster",
            tiles: [
                "https://tiles.maps.eox.at/wmts/1.0.0/s2cloudless_3857/default/g/{z}/{y}/{x}.jpg",
            ],
            tileSize: 256,
            attribution: '<a xmlns:dct="http://purl.org/dc/terms/" href="https://s2maps.eu" property="dct:title">Sentinel-2 cloudless - https://s2maps.eu</a> by <a xmlns:cc="http://creativecommons.org/ns#" href="https://eox.at" property="cc:attributionName" rel="cc:attributionURL">EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2016 &amp; 2017) released under <a rel="license" href="https://creativecommons.org/licenses/by/4.0/">CC by 4.0 International License</a>.',
            maxzoom: 19,
        },
    },
    layers: [
        {
            id: "osm",
            type: "raster",
            source: "osm",
            layout: { visibility: "visible" },
            paint: { 
                "raster-saturation": -1, 
                "raster-contrast": -0.2,
                "raster-opacity": 0.7,
            },
        },
        {
            id: "osmhot",
            type: "raster",
            source: "osmhot",
            layout: { visibility: "none" },
        },
        {
            id: "satellite",
            type: "raster",
            source: "satellite",
            layout: { visibility: "none" },
        },        
    ],
};

export const BG_STYLE: maplibregl.StyleSpecification = {
    version: 8,
    sources: {
        osm: {
            type: "raster",
            tiles: ["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
            tileSize: 256,
            attribution:
                '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
            maxzoom: 19,
        }
    },
    layers: [
        {
            id: "osm",
            type: "raster",
            source: "osm",
            layout: { visibility: "visible" },
            paint: { "raster-saturation": -1 },
        }]
};


export function mapMarker(path: any, color: string = 'white', iconColor: string = 'white', width: number = 512, height: number = 512, size: number = 20, scale= 0.5) {
    return `
    <svg 
        width="${size}" 
        height="${size}" 
        fill="none" xmlns="http://www.w3.org/2000/svg"  
        viewBox="0 0 512 512" 
        style="transform-origin: 0.5em 0.5em;"> 
        <circle cx="256" cy="256" r="240" fill="${color}" stroke="${iconColor}" stroke-width="40"/>
        <g transform="translate(256 256)">
        <g transform="translate(0, 0)  scale(${scale}, ${scale})">        
            <path 
            d="${path.icon[4]}"
            fill="${iconColor}" 
            transform="translate(-${width / 2} -${height / 2})"></path>
        </g>
        </g>
    </svg>`
}
