import React from 'react';

import styled from '@emotion/styled';

import { faBoltLightning, faSolarPanel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from '../Shared/Container';
import LossesVoltage from './LossesVoltage';
import { CircleStyle, LegendStyle } from '../../styles/styles';
import { COLORS, COLORS_BASE } from '../../utils/consts';
import { CircleProps, MapType, AllScenariosType, ManualSelectionModeType, LossesVoltageLegendCombinedType } from '../../types/dataTypes';
import Line from '../Shared/Line';
import useIsFeederInParams from '../../hooks/useIsFeederInParams';
import Point from '../Shared/Point';

type LegendProps = {
  selectedScenario: string,
  allScenarioData: AllScenariosType,
  map: MapType,
  viewLossOrVoltage: string,
  setViewLossOrVoltage:  React.Dispatch<React.SetStateAction<string>>,
  manualSelectionStage: ManualSelectionModeType,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function Legend({ selectedScenario, map, allScenarioData, viewLossOrVoltage, setViewLossOrVoltage, manualSelectionStage, lossVoltageLegendData }: LegendProps) {
  const isFeederInParams = useIsFeederInParams();

  return (
    <LegendContainerStyle>
      {isFeederInParams && <>
        <Container>
          {allScenarioData&&<LossesVoltage lossVoltageLegendData={lossVoltageLegendData} manualSelectionStage={manualSelectionStage} viewLossOrVoltage={viewLossOrVoltage} setViewLossOrVoltage={setViewLossOrVoltage} map={map} selectedScenario={selectedScenario} allScenarioData={allScenarioData}/>}
        </Container>
      </>}
      <Container>
        <LegendStyle>
          {!isFeederInParams && <>
            <HvGrid />
            <MvGrid />
          </>}
          {isFeederInParams && <>
            <Transformer />
            <Substation />
            {selectedScenario !== 'baseline' && <PV />}
          </>}
        </LegendStyle>
      </Container>
    </LegendContainerStyle>
  )
}

export default Legend;

const LegendContainerStyle = styled.div<{}>(() => `
  display: flex;
  align-items: flex-end;
`)

const Circle = ({ color, size, borderColor, borderSize }: CircleProps) => <div><CircleStyle color={color} size={size} borderColor={borderColor} borderSize={borderSize} /></div>
const SubstationSvg = () => <div><CircleStyle color={COLORS_BASE.GRAYS[1]} size={'18'}><FontAwesomeIcon icon={faBoltLightning} color="white" transform={{ size: 10 }} /></CircleStyle></div>
const PVSvg = () => <div><CircleStyle color={'white'} size={'18'} borderColor={COLORS_BASE.GREENS[0]}><FontAwesomeIcon icon={faSolarPanel} color={COLORS_BASE.GREENS[0]} transform={{ size: 10 }} /></CircleStyle></div>

const MvGrid = () => <><Point color={COLORS.MAP.MV_LINE} diameter="15px" /><p>Feeder Substations</p></>
const HvGrid = () => <><Line color={COLORS.MAP.HV_LINE} thickness="2px"/><p>High-voltage grid</p></>
const Substation = () => <><SubstationSvg /><p>Substation</p></>
const PV = () => <><PVSvg /><p>Feed-in point</p></>
const Transformer = () => <><Circle color="white" size="7" borderColor="#000" borderSize='1px' /><p>Transformer</p></>
