import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { COLORS, COLORS_BASE } from '../../utils/consts';

type SliderProps = {
    min: number,
    max: number,
    diff: number,
    val: number,
    units: string,
    name: string,
    ariaLabel: string,
    optionalSetState?: React.Dispatch<React.SetStateAction<number>>, 
};

function Slider({ min, max, units, diff, val, name, ariaLabel, optionalSetState }: SliderProps) {
    const [valState, setValState] = useState<number>(val?val:min);
    const handleSliderChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValState(Number(e.target.value));
        if(optionalSetState){optionalSetState(Number(e.target.value))}
    }, [optionalSetState])

    return (
        <SliderContainerStyle>
            <SliderInputStyle type="range" name={name} id={name} aria-label={ariaLabel} min={min} max={max} step={diff} value={valState} onChange={handleSliderChange} />
            <SliderTextStyle leftPosition={`${((valState - 0.5) / (max - 0.35)) * 100}%`}>{valState} MW</SliderTextStyle>
            <SliderLeftValueStyle>{min}{units && units}</SliderLeftValueStyle>
            <SliderRightValueStyle>{max}{units && units}</SliderRightValueStyle>
        </SliderContainerStyle>
    )
}

export default Slider;

const SliderContainerStyle = styled.div<{}>(() => `
    width: 100%;
    padding: 70px 0 50px 0;
    position: relative;
`)

const SliderLeftValueStyle = styled.div<{}>(() => `
    color: ${COLORS_BASE.BLACKS[0]};
    margin-top: 15px;
    position: absolute;
    left: 0px;
`)

const SliderRightValueStyle = styled.div<{}>(() => `
    color: ${COLORS_BASE.BLACKS[0]};
    margin-top: 15px;
    position: absolute;
    right: 0px;
`)

const SliderTextStyle = styled.div<{ leftPosition?: string }>(({ leftPosition = "10px" }) => `
    color: ${COLORS_BASE.BLACKS[0]};
    font-weight: 800;
    border: 2px solid ${COLORS_BASE.GRAYS[0]};
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    width: 60px;
    text-align: center;
    left: calc(${leftPosition} - 25px);
    top: 30px;
`)

const SliderInputStyle = styled.input<{}>(() => `
    height: 2px;
    width: 100%;
    outline: none;
    -webkit-appearance: none;
    background: ${COLORS_BASE.GRAYS[0]};
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid ${COLORS_BASE.GRAYS[0]};
        background: ${COLORS.BUTTON.MAIN.BG};
        cursor: pointer;
    ::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid ${COLORS_BASE.GRAYS[0]};
        background: ${COLORS.BUTTON.MAIN.BG};
        cursor: pointer;
    }
`)