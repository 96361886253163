import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapType } from '../../types/dataTypes';
import useIsFeederInParams from '../../hooks/useIsFeederInParams';


type OverlayLayerProps = {
  layer: { text: string, id: string },
  map: MapType,
  initialState?: boolean,
}

function OverlayLayerToggle({ layer, map, initialState = true }: OverlayLayerProps) {
  const [viewLayer, setViewLayer] = useState<boolean>(initialState);
  const mapCurrent = map.current;
  const isFeederInParams = useIsFeederInParams();

  useEffect(() => {
    if (!mapCurrent) return;
    setViewLayer(!isFeederInParams);
  },[isFeederInParams, mapCurrent])

  const toggleLayer = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    if (!mapCurrent) { return }
    setViewLayer(!viewLayer);
    !viewLayer ? mapCurrent.setLayoutProperty(layer.id, 'visibility', 'visible') : mapCurrent.setLayoutProperty(layer.id, 'visibility', 'none');
    }, [layer.id, mapCurrent, viewLayer]);

  return (
    <LayerStyle>
      <CheckboxStyle type="checkbox" value={layer.id} id={layer.id} checked={viewLayer} onChange={toggleLayer} />
      <LabelStyle htmlFor={layer.id}>
        <FontAwesomeIcon icon={viewLayer ? 'square-check' : ['far', 'square']} />
        {layer.text}
      </LabelStyle>
    </LayerStyle>
  )
}

export default OverlayLayerToggle;

const LayerStyle = styled.li<{}>(() => `
    margin: 10px 0;
    padding: 0;
    &:hover{
      cursor: pointer;
    }
`)

const CheckboxStyle = styled.input<{}>(() => `
    display: none;
    &:hover{
      cursor: pointer;
    }
`)

const LabelStyle = styled.label<{}>(() => `
    &:hover{
      cursor: pointer;
    }
    > svg{
        padding: 0 10px 0 0;
    }
`)