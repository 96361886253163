import React from 'react';
import { Svg, Path, View, StyleSheet } from '@react-pdf/renderer';
import { GeoJSON2SVG } from 'geojson2svg';
import { GeoJSON } from 'geojson'
import { FeederGeodataType, FeederMetaType, ScenariosType } from '../../../types/dataTypes';
import { COLORS_BASE } from '../../../utils/consts';

type SvgMapType = {
  feederGeodata: FeederGeodataType,
  selectedFeederData: FeederMetaType,
  scenario: ScenariosType,
}

function SvgMap({ feederGeodata, selectedFeederData, scenario }: SvgMapType) {
  if (!feederGeodata || !feederGeodata.transformers || !feederGeodata.lines || !feederGeodata.substation ||
    !selectedFeederData || !selectedFeederData?.bbox_array || !scenario) return null;

  const viewPortSize = { width: 350, height: 350 };
  const mapExtent = {
    left: selectedFeederData?.bbox_array[0][0],
    bottom: selectedFeederData?.bbox_array[0][1],
    right: selectedFeederData?.bbox_array[2][0],
    top: selectedFeederData?.bbox_array[2][1]
  }

  // const testPath = faBoltLightning.icon[4]

  const transformersConverter = new GeoJSON2SVG({
    r: 3, output: 'path',
    mapExtent: mapExtent,
    viewportSize: viewPortSize,
  });
  const linesConverter = new GeoJSON2SVG({
    output: 'path',
    mapExtent: mapExtent,
    viewportSize: viewPortSize,
  });
  const substationConverter = new GeoJSON2SVG({
    r: 7,
    output: 'path',
    mapExtent: mapExtent,
    viewportSize: viewPortSize,
  });
  const pvConverter = new GeoJSON2SVG({
    r: 7,
    output: 'path',
    mapExtent: mapExtent,
    viewportSize: viewPortSize,
  });

  const transformersGeoJSON: GeoJSON | undefined = feederGeodata?.transformers;
  const linesGeoJSON: GeoJSON | undefined = feederGeodata?.lines;
  const substationGeoJSON: GeoJSON | undefined = feederGeodata?.substation;
  const pvGeoJSON: GeoJSON | undefined = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            scenario?.bus_long,
            scenario?.bus_lat
          ]
        },
        "properties": {}
      }
    ]
  }

  const transformersSvgPath = transformersConverter.convert(transformersGeoJSON);
  const linesSvgPath = linesConverter.convert(linesGeoJSON);
  const substationSvgPath = substationConverter.convert(substationGeoJSON);
  const pvSvgPath = pvConverter.convert(pvGeoJSON);

  console.log(substationGeoJSON)
  // console.log(faBoltLightning.icon[4])

  return (
    <View style={styles.svgContainer}>
      <Svg width="500" height="500" viewBox="-10 -10 550 550">
        {linesSvgPath.map((line) => { return <Path d={line} fill="#FFF" stroke="#000000" strokeWidth={0.5} key={linesSvgPath.indexOf(line)} /> })}
        {transformersSvgPath.map((transformer) => { return <Path d={transformer} fill="#FFF" stroke="#000000" strokeWidth={0.5} key={transformersSvgPath.indexOf(transformer)} /> })}
        {substationSvgPath.map((substation) => {
          return <Path d={substation} fill={COLORS_BASE.GRAYS[1]} stroke="#fff" strokeWidth={2} key={substationSvgPath.indexOf(substation)} />
        })}
        {scenario?.bus_long && pvSvgPath.map((pv) => {
          return <Path d={pv} fill={COLORS_BASE.GREENS[0]} stroke="#fff" strokeWidth={2} key={substationSvgPath.indexOf(pv)} />
        })}
        {/* <Path d={`${testPath}`} fill="#e09809" stroke="#000000" strokeWidth={0.5} /> */}
      </Svg>
    </View>
  )
}

export default SvgMap

const styles = StyleSheet.create({
  svgContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});