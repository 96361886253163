import { getData } from "./base.service";

function getFeederData(url: string){
    return getData(url, {'Authorization': 'Bearer '+localStorage.getItem("access_token")})
}

export function getFeederNames() {
    const feederNames: Promise<any> = getFeederData('feeder')
    // feederNames.then((response)=> returnre)
    return feederNames
}

export function getSelectedFeederData(feederId: string) {
    const selectedFeederData: Promise<any> = getFeederData(`feeder/${feederId}`)
    return selectedFeederData
}

export function getFeederScenarios(feederId: string) {
    if(!feederId){return null}
    const selectedFeederScenarios: Promise<any> = getFeederData(`scenarios/${feederId}`)
    return selectedFeederScenarios
}

export function getFeederGeodata(feederId: string) {
    if(!feederId){return null}
    const selectedFeederGeodata: Promise<any> = getFeederData(`geodata/${feederId}`)
    return selectedFeederGeodata
}

export function getFeederScenarioLineResults(feederId: string, scenarioId: string) {
    if(!feederId || !scenarioId){return null}
    const selectedFeederScenarioResults: Promise<any> = getFeederData(`scenarios/${feederId}/${scenarioId}`)
    return selectedFeederScenarioResults
}

export function getSubstationsAll() {
    const selectedSubstationsALl: Promise<any> = getFeederData(`geodata/substations`)
    return selectedSubstationsALl
}

export function getTrasnmissionLines() {
    const selectedTrasnsmissionLines: Promise<any> = getFeederData(`geodata/transmissionlines`)
    return selectedTrasnsmissionLines
}