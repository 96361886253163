import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapType } from '../../types/dataTypes';

type BaseLayerProps = {
    layer: { text: string, id: string },
    layerArray: { text: string, id: string }[],
    map: MapType,
    selectedLayer: string,
    setSelectedLayer: React.Dispatch<React.SetStateAction<string>>;
}

function BaseLayerSelect({ layer, layerArray, map, selectedLayer, setSelectedLayer }: BaseLayerProps) {
    const mapCurrent = map.current;

    const changeBaselayer = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (!mapCurrent) { return }
        const layerId = e.currentTarget.value;
        setSelectedLayer(layerId);
        layerArray.forEach(layer => (layer.id !== layerId) ? mapCurrent.setLayoutProperty(layer.id, 'visibility', 'none') : mapCurrent.setLayoutProperty(layer.id, 'visibility', 'visible'));
    }, [layerArray, mapCurrent, setSelectedLayer]);

    return (
        <BaseLayerStyle>
            <CheckboxStyle type="radio" value={layer.id} id={layer.id} checked={(selectedLayer === layer.id)} onChange={changeBaselayer} />
            <LabelStyle htmlFor={layer.id}>
                <FontAwesomeIcon icon={(selectedLayer === layer.id) ? 'square-check' : ['far', 'square']} />
                {layer.text}
            </LabelStyle>
        </BaseLayerStyle>
    )
}

export default BaseLayerSelect;

const BaseLayerStyle = styled.li<{}>(() => `
    margin: 10px 0;
    padding: 0;
    &:hover{
      cursor: pointer;
    }
`)

const CheckboxStyle = styled.input<{}>(() => `
    display: none;
    &:hover{
      cursor: pointer;
    }
`)

const LabelStyle = styled.label<{}>(() => `
    &:hover{
      cursor: pointer;
    }
    > svg{
        padding: 0 10px 0 0;
    }
`)