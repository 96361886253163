import React from 'react';
import styled from '@emotion/styled';
import { COLORS, GLOBAL_SIZES } from '../../utils/consts';

import Container from '../Shared/Container';
import AppName from './AppName';
import Menu from './Menu';
import { AuthenticateType, UserType } from '../../types/dataTypes';

type HeaderProps = {
  authenticate: AuthenticateType,
  user: UserType
}

function Header( { authenticate, user }: HeaderProps ) {

  return (
    <HeaderStyle>
      <Container flex padding="0 25px" height={`${GLOBAL_SIZES.HEADER}px`}>
        <AppName/>
        <Menu authenticate={authenticate} user={user}/>
      </Container>
    </HeaderStyle>
  )
}

export default Header;

const HeaderStyle = styled.header<{}>(() => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: ${COLORS.FONT.SECONDARY};
  /* z-index: 1000; */
  /* Comment out z-index to allow for PV selection to work */
`)