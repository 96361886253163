import React from 'react';

import { Text, StyleSheet, View } from '@react-pdf/renderer';
import { PAGE_TITLE } from '../../../utils/consts';


type FooterType = {
  pageNum: number | undefined,
}

function Footer({ pageNum }: FooterType) {
  let currentDateTime = new Date().toLocaleString();

  return (
    <View style={styles.footer}>
      <View>
        <Text>{PAGE_TITLE}</Text>
        <Text>{currentDateTime}</Text>
      </View>
      <Text>Page {pageNum}</Text>
    </View>
  )
}

export default Footer

const styles = StyleSheet.create({
  footer: {
    fontSize: '10pt',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '500px'
  }
});