import React, { useEffect, useRef, useState } from 'react';

import maplibregl from 'maplibre-gl';

import { STYLE, MAP_CENTRE, MAP_BOUNDS, MAP_PADDING } from '../../utils/mapConsts';

import FeederLayer from './MapLayers/FeederLayer';
import FeederDissolvedLayer from './MapLayers/FeederDissolvedLayer';
import SubstationLayer from './MapLayers/SubstationLayer';
import TransformerLayer from './MapLayers/TransformerLayer';
import TransformerModelledLayer from './MapLayers/TransformerModelledLayer';

import { FeederMetaType, FeederGeodataType, AllScenariosType, MapType, ManualSelectionModeType, LossesVoltageLegendCombinedType, SetPdfDataType, ScenariosType, SelectedPVCapacityType, SelectedScenarioLineDataType } from '../../types/dataTypes';
import { MapStyle } from '../../styles/styles';
import PVLayer from './MapLayers/PVLayer';
import HvLayer from './MapLayers/HvLayer';
import { SCENARIOS } from '../../utils/consts';

type MapProps = {
  map: MapType,
  selectedScenario: string,
  selectedFeederData: FeederMetaType,
  allScenarioData: AllScenariosType,
  manualSelectionStage: ManualSelectionModeType,
  setManualSelectionStage: React.Dispatch<React.SetStateAction<ManualSelectionModeType>>,
  setPdfData: SetPdfDataType,
  feederGeodata: FeederGeodataType,
  setFeederGeodata: React.Dispatch<React.SetStateAction<FeederGeodataType>>,
  selectedPVCapacity: SelectedPVCapacityType,
  selectedScenarioLineData: SelectedScenarioLineDataType,
  selectedScenarioData: ScenariosType,
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<ScenariosType>>,
  viewLossOrVoltage: string,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function Map({
  map,
  selectedScenario,
  allScenarioData,
  selectedFeederData,
  manualSelectionStage,
  setManualSelectionStage,
  feederGeodata,
  setFeederGeodata,
  selectedPVCapacity,
  selectedScenarioData,
  setSelectedScenarioData,
  selectedScenarioLineData,
  viewLossOrVoltage,
  lossVoltageLegendData
}: MapProps) {

  const mapContainerRef = useRef<any>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (map.current) return; // initialize map only once

    map.current = new maplibregl.Map({
      container: mapContainerRef.current,
      style: STYLE,
      center: MAP_CENTRE,
      zoom: 6,
      minZoom: 5, // how far out we can go
      maxZoom: 16, // how far in we can go
    });

    map.current.on('load', function () {
      setMapLoaded(true);
      if (map.current) {
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();
        map.current.touchPitch.disable();
        map.current.fitBounds(MAP_BOUNDS, {padding: MAP_PADDING})
      }
    });
  }, [map, setMapLoaded]);


  return (
    <>
      <MapStyle ref={mapContainerRef} />
      {mapLoaded&&<HvLayer map={map} />}
      {mapLoaded&&<FeederDissolvedLayer map={map} setFeederGeodata={setFeederGeodata}/>}
      <FeederLayer
        map={map}
        feederLinesGeodata={feederGeodata?.lines}
        selectedScenarioLineData={selectedScenarioLineData}
        lossVoltageLegendData={lossVoltageLegendData}
      />

      <SubstationLayer
        map={map}
        feederSubstationGeodata={feederGeodata?.substation}
      />
      <TransformerLayer
        map={map}
        feederTransformersGeodata={feederGeodata?.transformers}
      />
      {manualSelectionStage === 'select-location' && selectedScenario === SCENARIOS.MANUAL.TEXT_ID &&
        <TransformerModelledLayer
          map={map}
          allScenarioData={allScenarioData}
          setManualSelectionStage={setManualSelectionStage}
          selectedPVCapacity={selectedPVCapacity}
          setSelectedScenarioData={setSelectedScenarioData}
          viewLossOrVoltage={viewLossOrVoltage}
          feederLinesGeodata={feederGeodata?.lines}
          lossVoltageLegendData={lossVoltageLegendData}
        />
      }
      <PVLayer map={map}
        selectedScenario={selectedScenario}
        selectedScenarioData={selectedScenarioData}
      />
    </>
  )
}

export default Map;
