import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import maplibregl from 'maplibre-gl';

import Button from '../Shared/Button';
import Container from '../Shared/Container';
import Error from '../Shared/Error';
import AppName from '../Header/AppName';
import InputText from '../Shared/InputText';

import { LoginFormType, AuthenticateType, ErrorMessageType } from '../../types/dataTypes';

import { MapStyle, OverlayContainerStyle, ButtonContainerStyle } from '../../styles/styles';

import { BG_STYLE } from '../../utils/mapConsts';

import { login } from '../../services/auth.service';

type LoginProps = {
    authenticate: AuthenticateType,
}

function Login({ authenticate }: LoginProps) {
    const bgMap = useRef<maplibregl.Map | null>(null);
    const bgMapContainerRef = useRef<any>(null);
    const [loginForm, setLoginForm] = useState<LoginFormType>({
        username: "",
        password: ""
    });
    const { username, password } = loginForm;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null)

    useEffect(() => {
        if (typeof window === "undefined") return;
        if (bgMap.current) return; // initialize map only once
        bgMap.current = new maplibregl.Map({
            container: bgMapContainerRef.current,
            style: BG_STYLE,
            center: [70.477, 30.657],
            zoom: 6,
            minZoom: 5, // how far out we can go
            maxZoom: 16, // how far in we can go
        });
    }, [bgMap]);

    const handleLoginFormChange = (e: any) => {
        const { name, value } = e.target;
        return setLoginForm({ ...loginForm, [name]: value });
    }

    const handleLoginFormSubmission = (e: any) => {
        e.preventDefault();
        setErrorMessage(null)
        const credentials = { username, password };
        login(credentials).then((res) => {
            if (!res) {
                setErrorMessage("No response from server");
                return;
            } 
            if (!res.access_token) {
                setErrorMessage("Invalid username or password");
                return;
            } else {
                localStorage.setItem('access_token', res.access_token);
                authenticate(username);
                navigate('/')
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <>
            <MapStyle ref={bgMapContainerRef} />
            <Error errorMessage={errorMessage} />
            <OverlayContainerStyle>
                <EnablePointerEventsStyle>
                    <Container width="70vw" margin="0 auto" maxWidth='500px'>
                        <ContainerContentStyle>
                            <AppName />
                            <LoginFormStyle onSubmit={handleLoginFormSubmission}>
                                <InputText inputId='username' title='Username' placeholder="Enter username" value={username} required onChange={handleLoginFormChange} />
                                <InputText inputId='password' title='Password' type='password' placeholder='Enter password' value={password} required onChange={handleLoginFormChange} />
                                <ButtonContainerStyle>
                                    <Button
                                        type='submit'
                                        filledStyle={true} >
                                        Login <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                </ButtonContainerStyle>
                            </LoginFormStyle>
                        </ContainerContentStyle>
                    </Container>
                </EnablePointerEventsStyle>
            </OverlayContainerStyle>
        </>
    )
}

export default Login;

const EnablePointerEventsStyle = styled.div<{}>(() => `
    pointer-events: auto;
`)

const LoginFormStyle = styled.form<{}>(() => `
    max-width: 300px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`)

const ContainerContentStyle = styled.div<{}>(() => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 50px 0;
`)