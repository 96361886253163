import React, { useCallback, useEffect, useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PopupDataContextType } from '../../../types/contextTypes';
import { PopupDataContext } from '../../../context/popupDataContext';
import { SelectedScenarioLineDataType, FeederLinesType, LossesVoltageLegendCombinedType, AllScenariosType, FeederTransformersModelledFeaturesType, MapType, ManualSelectionModeType, SelectedPVCapacityType, ScenariosType } from '../../../types/dataTypes';

import { searchParamsObj, formatPvPopupData } from '../../../utils/dataHandler';
import { mapCursorNormal, mapCursorPointer, extractLineScenarioValues, mapVisibilityVisible, mapVisibilityNone } from '../../../utils/mapDataHandler';
import { getFeederScenarioLineResults } from '../../../services/getFeederData.service'
import { LAYER_IDS } from '../../../utils/mapConsts';
import { LOSS_VOLTAGE } from '../../../utils/consts';

type TransformerModelledLayerProps = {
  map: MapType,
  allScenarioData: AllScenariosType,
  setManualSelectionStage: React.Dispatch<React.SetStateAction<ManualSelectionModeType>>,
  selectedPVCapacity: SelectedPVCapacityType,
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<ScenariosType>>,
  feederLinesGeodata: FeederLinesType,
  viewLossOrVoltage: string,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function TransformerModelledLayer({ map, allScenarioData, setManualSelectionStage,
  selectedPVCapacity, setSelectedScenarioData, viewLossOrVoltage, feederLinesGeodata, lossVoltageLegendData }: TransformerModelledLayerProps) {
  const [searchParams] = useSearchParams();
  const selectedFeeder = searchParamsObj(searchParams)?.feeder;
  const mapCurrent = map.current;
  const { updatePvPopupData, resetAllPopupData } = useContext(PopupDataContext) as PopupDataContextType

  const { FEEDER_DETAILED, TRANSFORMERS, TRANSFORMERS_MODELLED } = LAYER_IDS

  useEffect(() => {
    if (!mapCurrent || !selectedFeeder) return;
    mapVisibilityVisible(map, TRANSFORMERS_MODELLED);
    return () => {
      mapVisibilityNone(map, TRANSFORMERS_MODELLED);
    };
  }, [TRANSFORMERS_MODELLED, map, mapCurrent, selectedFeeder])

  const handleSelectLocationClick = useCallback((e: any) => {
    resetAllPopupData(map);
    if (!mapCurrent || !e.features || !allScenarioData) return;
    const busIdSelected = e.features[0].properties.bus_id;
    const selectedScenario = allScenarioData.filter((scen) => ((scen?.bus_id === busIdSelected) && (Number(scen?.plant_capacity) === Number(selectedPVCapacity))));
    setManualSelectionStage("location-selected");
    setSelectedScenarioData(selectedScenario[0]);
    updatePvPopupData(formatPvPopupData(selectedScenario[0]))

    const scenId = selectedScenario[0]?.scenario_id;
    if (!scenId && scenId !== 0) return;

    getFeederScenarioLineResults(selectedFeeder, scenId.toString())?.then(
      (res: SelectedScenarioLineDataType) => {
        console.log("in transformers modelled", extractLineScenarioValues(feederLinesGeodata, res))
        if (mapCurrent.getLayer(FEEDER_DETAILED)) {
          mapCurrent.removeLayer(FEEDER_DETAILED);
          mapCurrent.removeSource(FEEDER_DETAILED);
        }
        if (!mapCurrent.getSource(FEEDER_DETAILED)) {
          mapCurrent.addSource(FEEDER_DETAILED, {
            "type": "geojson",
            "data": {
              "type": feederLinesGeodata?.type,
              "features": extractLineScenarioValues(feederLinesGeodata, res),
            }
          })
        }
        if (!mapCurrent.getLayer(FEEDER_DETAILED)) {
          mapCurrent.addLayer({
            id: FEEDER_DETAILED,
            source: FEEDER_DETAILED,
            type: 'line',
            paint: {
              "line-color": '#000',
              "line-width": 4,
            },
            layout: {
              visibility: 'visible',
              "line-cap": "round",
            }
          }, TRANSFORMERS);
          viewLossOrVoltage === LOSS_VOLTAGE.LOSS.ID ? mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.losses.lineColor) : mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.voltageDrop.lineColor);
        }
      }).catch((err: any) => console.log(err));

  }, [FEEDER_DETAILED, TRANSFORMERS, allScenarioData, feederLinesGeodata, lossVoltageLegendData.losses.lineColor, lossVoltageLegendData.voltageDrop.lineColor, map, mapCurrent, resetAllPopupData, selectedFeeder, selectedPVCapacity, setManualSelectionStage, setSelectedScenarioData, updatePvPopupData, viewLossOrVoltage])

  const handleSelectLocationClickRef = useRef<any>(null);

  if (!mapCurrent || !allScenarioData) return null;
  mapCurrent.off('click', TRANSFORMERS_MODELLED, handleSelectLocationClickRef.current)
  const transformersModelledGeoJSON: FeederTransformersModelledFeaturesType = allScenarioData.filter((scen: ScenariosType) => (Number(scen?.plant_capacity) === Number(selectedPVCapacity))).map((transformer: any) => ({
    geometry: {
      type: 'Point',
      coordinates: [Number(transformer.bus_long), Number(transformer.bus_lat)]
    },
    properties: {
      bus_id: transformer.bus_id
    }
  })
  )

  mapCurrent.off('click', TRANSFORMERS_MODELLED, handleSelectLocationClickRef.current)

  if (selectedFeeder) {
    handleSelectLocationClickRef.current = handleSelectLocationClick;

    if (!mapCurrent.getSource(TRANSFORMERS_MODELLED)) {
      mapCurrent.addSource(TRANSFORMERS_MODELLED, {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": transformersModelledGeoJSON,
        }
      });
    }
    if (!mapCurrent.getLayer(TRANSFORMERS_MODELLED)) {
      mapCurrent.addLayer({
        id: TRANSFORMERS_MODELLED,
        source: TRANSFORMERS_MODELLED,
        type: 'circle',
        paint: {
          "circle-color": '#000',
          "circle-radius": 5.5,
          "circle-stroke-color": '#fff',
          "circle-stroke-width": 2.0,
        },
        layout: {
          visibility: 'visible',
        }
      });
    }
    mapCurrent.on('click', TRANSFORMERS_MODELLED, handleSelectLocationClickRef.current);
  }
  if (mapCurrent.getLayer(TRANSFORMERS_MODELLED)) {
    mapCurrent.on('mouseenter', TRANSFORMERS_MODELLED, () => { mapCursorPointer(map) });
    mapCurrent.on('mouseleave', TRANSFORMERS_MODELLED, () => { mapCursorNormal(map) });
  }
  return null;
}

export default TransformerModelledLayer;