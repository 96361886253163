import React from 'react';
import styled from '@emotion/styled';

import Container from '../Shared/Container';
import SkeletonLoader from '../Shared/SkeletonLoader';

import { capitalizeWordsInString, returnNoData } from '../../utils/dataHandler';
import { FeederMetaType } from '../../types/dataTypes';

type FeederInfoBoxProps = {
    selectedFeederData: FeederMetaType,
}

function FeederInfoBox({ selectedFeederData }: FeederInfoBoxProps) {
    
    const name = selectedFeederData?capitalizeWordsInString(selectedFeederData.name):undefined;
    const id = selectedFeederData?`#${selectedFeederData.id}`:undefined;
    const length = selectedFeederData?`${Number(selectedFeederData.length).toFixed(1)} km`:undefined;
    const transformerCount = selectedFeederData?`${selectedFeederData.transformer_count}`:undefined;
    const connectedKva = selectedFeederData?`${Number(selectedFeederData.connected_kva).toFixed(1)} kVA`:undefined;
    const peakLoad = selectedFeederData?`${Number(selectedFeederData.peak_load).toFixed(1)} kW`:undefined;
    const lastRun = selectedFeederData?.last_run?new Date(selectedFeederData?.last_run):null 
    const lastRunMonth = lastRun?`${lastRun.getDate()}/${lastRun.getMonth() + 1}/${lastRun.getFullYear()}`:''

    return (
        <Container>
            <FeederInfoBoxStyle>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <td><SkeletonLoader value={returnNoData(name)}/></td>
                    </tr>
                    <tr>
                        <th>Feeder Code</th>
                        <td><SkeletonLoader value={returnNoData(id)}/></td>
                    </tr>
                    <tr>
                        <th>Total length</th>
                        <td><SkeletonLoader value={returnNoData(length)}/></td>
                    </tr>
                    <tr>
                        <th>Transformers</th>
                        <td><SkeletonLoader value={returnNoData(transformerCount)}/></td>
                    </tr>
                    <tr>
                        <th>Total capacity</th>
                        <td><SkeletonLoader value={returnNoData(connectedKva)}/></td>
                    </tr>
                    <tr>
                        <th>Peak load</th>
                        <td><SkeletonLoader value={returnNoData(peakLoad)}/></td>
                    </tr>
                    <tr>
                        <th>Date updated</th>
                        <td><SkeletonLoader value={returnNoData(lastRunMonth)}/></td>
                    </tr>
                </tbody>
            </FeederInfoBoxStyle>
        </Container>
    )
}

export default FeederInfoBox;

const FeederInfoBoxStyle = styled.table<{}>(() => `
    text-align: left;
    th {
        width: 60%;
    }
    td, th {
        padding-bottom: 7px;
    }
`)

