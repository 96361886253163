import React, { useCallback, useRef, useContext } from 'react';
import maplibregl from 'maplibre-gl';

import { useSearchParams } from 'react-router-dom';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { PopupDataContextType } from '../../../types/contextTypes';
import { PopupDataContext } from '../../../context/popupDataContext';

import { FeederTransformersType, MapType } from '../../../types/dataTypes';
import { mapMarker, LAYER_IDS } from '../../../utils/mapConsts';
import { mapCursorNormal, mapCursorPointer } from '../../../utils/mapDataHandler';

import { searchParamsObj } from '../../../utils/dataHandler';
import { COLORS, COLORS_BASE } from '../../../utils/consts';

type TransformerLayerProps = {
  map: MapType,
  feederTransformersGeodata: FeederTransformersType,
}

function TransformerLayer({ map, feederTransformersGeodata }: TransformerLayerProps) {
  const [searchParams] = useSearchParams();
  const selectedFeeder = searchParamsObj(searchParams)?.feeder;
  const mapCurrent = map.current;
  const handleClickRef = useRef<any>(null);
  const selectedTransformerMarker = useRef<maplibregl.Marker | null>(null);
  const { updateTransformerPopupData } = useContext(PopupDataContext) as PopupDataContextType
  const { TRANSFORMERS } = LAYER_IDS;

  const el = document.createElement('div');
  el.setAttribute("id", "transformer-marker");
  el.innerHTML = mapMarker(faCircle, '#fff', COLORS_BASE.BLACKS[0], 512, 512, 20, 0.6);

  const handleClick = useCallback((e: any) => {
    if (!mapCurrent || !e.features) return;
    if (selectedTransformerMarker.current) { selectedTransformerMarker.current.remove() }
    const features = e.features[0];
    const json = features.toJSON();
    updateTransformerPopupData(json);
    const selectedTransformerCoords = json?.geometry.coordinates;
    const coordsTyped: [number, number] = [selectedTransformerCoords[0], selectedTransformerCoords[1]];
    selectedTransformerMarker.current = new maplibregl.Marker(el).setLngLat(coordsTyped).addTo(mapCurrent);
  }, [el, mapCurrent, updateTransformerPopupData])

  if (!mapCurrent || !feederTransformersGeodata) return null;
  // const feederTransformersGeodataFeaturesSwitched = switchLatLon(feederTransformersGeodata.features);
  mapCurrent.off('click', TRANSFORMERS, handleClickRef.current)
  if (selectedFeeder) {
    handleClickRef.current = handleClick;
    if (!mapCurrent.getSource(TRANSFORMERS)) {
      mapCurrent.addSource(TRANSFORMERS, {
        "type": "geojson",
        "data": {
          "type": feederTransformersGeodata.type,
          "features": feederTransformersGeodata.features,
        }
      });
    }
    if (!mapCurrent.getLayer(TRANSFORMERS)) {
      mapCurrent.addLayer({
        id: TRANSFORMERS,
        source: TRANSFORMERS,
        type: 'circle',
        paint: {
          "circle-color": COLORS.MAP.TRANSFORMERS.FILL,
          "circle-radius": 3.5,
          "circle-stroke-color": COLORS.MAP.TRANSFORMERS.BORDER,
          "circle-stroke-width": 0.5,
        },
        layout: {
          visibility: 'visible',
        }
      });
      if (mapCurrent.getLayer(LAYER_IDS.FEEDER_DETAILED)) { mapCurrent.moveLayer(LAYER_IDS.FEEDER_DETAILED, LAYER_IDS.TRANSFORMERS) }
    }
    mapCurrent.on('click', TRANSFORMERS, handleClickRef.current)
  }
  else {
    if (mapCurrent.getLayer(TRANSFORMERS)) {
      mapCurrent.removeLayer(TRANSFORMERS);
      mapCurrent.removeSource(TRANSFORMERS);
    }
    if (selectedTransformerMarker.current) { selectedTransformerMarker.current.remove() }
  }
  if (mapCurrent.getLayer(TRANSFORMERS)) {
    mapCurrent.on('mouseenter', TRANSFORMERS, () => { mapCursorPointer(map) });
    mapCurrent.on('mouseleave', TRANSFORMERS, () => { mapCursorNormal(map) });
  }
  return null;
}

export default TransformerLayer;

// function switchLatLon(features: any[]) {
//   return features.map(feature => ({...feature, geometry: {type: 'Point', coordinates: [feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}}));
// }