import React from 'react'
import { LineStyle } from '../../styles/styles';

type LineProps = { color: string, thickness: string, width?: string, dashed?: boolean };

function Line({ color, thickness, width, dashed }: LineProps) {
  return (
    <div><LineStyle color={color} thickness={thickness} width={width} dashed={dashed} /></div>
  )
}

export default Line