import { FeedersType, AllScenariosType, ScenariosType, MapType } from "../types/dataTypes";
import { SCENARIOS, COLORS_BASE } from "./consts";
import { LAYER_IDS } from "./mapConsts";

const { FEEDER_LINE_SELECTED } = LAYER_IDS;

export function capitalizeWordsInString(string: string | undefined) {
    if (!string) { return 'No data' }
    const words = string.toLowerCase().split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(" ");
};

export function returnNoData(string: string | undefined | null) {
    if (!string || string === 'null' || string.includes('null')) {
        return 'No data'
    } else return string
}

// FEEDER - Data handlers for feeder names / format and selecting a feeder
export function feederFormat(feederName: FeedersType) {
    return `${capitalizeWordsInString(feederName.name)} #(${feederName.id})`
}

export function modifyFeederArray(feederArray: FeedersType[]) {
    return feederArray.map(el => { return { id: el.id, name: el.name, idString: el.id.toString(), slug: feederFormat(el) } })
}

export function feederLookup(allFeederNames: FeedersType[] | null, searchTerm: string) {
    if (!allFeederNames) { return null; }
    const modifiedArray = modifyFeederArray(allFeederNames);
    const lookup = modifiedArray.find((feeder) => feeder.slug === searchTerm);
    if (!lookup) {
        return null;
    } else {
        return lookup
    }
};

export function searchParamsObj(searchParams: URLSearchParams) {
    const object = Object.fromEntries(searchParams.entries());
    return object;
}

export function toFeederSlug(feederFromFeederFormat: string | null) {
    if (!feederFromFeederFormat) {
        return '';
    } else {
        return `${feederFromFeederFormat.replace(/[\W_]+/g, '').toLowerCase()}`
    }
}

export function resetTransformerData(map: MapType) {
    if (!map.current) return;
    const markerDiv = document.getElementById("transformer-marker");
    if (markerDiv) { markerDiv.remove() }
}

export function resetFeederData(map: MapType) {
    if (!map.current) return;
    if (map.current.getLayer(FEEDER_LINE_SELECTED)) {
        map.current.removeLayer(FEEDER_LINE_SELECTED);
        map.current.removeSource(FEEDER_LINE_SELECTED);
    }
}

export function resetPvData(map: MapType) {
    if (!map.current) return;
}

export function formatCoords(lon: number | string | null | undefined, lat: number | string | null | undefined) {
    if (!lon || !lat) { return "No coordinates provided" }
    return `${Number(lon).toFixed(4)}, ${Number(lat).toFixed(4)}`
}

export function formatPvPopupData(selectedScenarioData: ScenariosType) {
    if (!selectedScenarioData?.plant_capacity) return null;
    return {
        lat: Number(selectedScenarioData?.bus_lat).toFixed(4),
        lon: Number(selectedScenarioData?.bus_long).toFixed(4),
        capacity: selectedScenarioData?.plant_capacity,
        land: selectedScenarioData?.land_required_ha,
        costs: `Rs. ${Math.round(Number(selectedScenarioData?.construction_costs_pkr))}/-`,
    }
}

export function formatScenarioNames(scenName: string | undefined) {
    if (!scenName) { return 'Unknown' }
    if (scenName === 'admin_selected') { return 'Manual' }
    return capitalizeWordsInString(scenName)
}

export function getScenarioData(allScenarioData: AllScenariosType, scenName: string) {
    if (!allScenarioData) {
        console.error("No scenario data given"); return null;
    }
    if (allScenarioData.length === 0) {
        console.error("Returned array length is 0"); return null;
    }
    const filtered = allScenarioData.filter((scen) => scen?.name === scenName)
    if (filtered.length === 0) {
        console.error(`No ${scenName} scenario provided`); return null;
    }
    if (filtered.length > 1) {
        console.error(`Multiple ${scenName} scenarios given, the first one is selected`, filtered);
    }
    const filteredFirstVal: ScenariosType = filtered[0]
    return filteredFirstVal;
}

export function getBaselineScenarioData(allScenarioData: AllScenariosType) {
    return getScenarioData(allScenarioData, SCENARIOS.BASE.TEXT_ID);
}

export function getOptimumScenarioData(allScenarioData: AllScenariosType) {
    return getScenarioData(allScenarioData, SCENARIOS.OPTIMAL.TEXT_ID);
}

export const isPropValuesEqual = (subject: any, target: any, propNames: string[]) =>
    propNames.every(propName => subject[propName] === target[propName]);

export const filterObjectByDuplicateProp = (items: any, propNames: string[]) =>
    items.filter((item: any, index: any, array: any[]) =>
        index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, propNames))
    );

export function extractQuantiles(arr: number[]) {
    const arrAscending = (arr: number[]) => arr.sort((a, b) => (a - b));
    // const arrSum = (arr: number[]) => arr.reduce((a, b) => (a + b), 0);
    // const arrMean = (arr: number[]) => arrSum(arr) / arr.length;

    const quantile = (arr: number[], quan: number) => {
        const sorted = arrAscending(arr);
        const pos = (sorted.length - 1) * quan;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return Math.round((sorted[base] + rest * (sorted[base + 1] - sorted[base])) * 10) / 10
        } else {
            return Math.round(sorted[base] * 10) / 10;
        }
    }

    return {
        quan25: quantile(arr, 0.25),
        quan50: quantile(arr, 0.50),
        quan75: quantile(arr, 0.75)
    }
}

export function createLegendFormat(arr: number[], type: 'losses' | 'voltage_drop') {
    const quants = extractQuantiles(arr);
    const legend = [
        { id: 1, min: 0.0, max: quants.quan25, text: `0 - ${quants.quan25}`, color: COLORS_BASE.GREENS[0] },
        { id: 2, min: quants.quan25, max: quants.quan50, text: `${quants.quan25} - ${quants.quan50}`, color: COLORS_BASE.GREENS[1] },
        { id: 3, min: quants.quan50, max: quants.quan75, text: ` ${quants.quan50} - ${quants.quan75}`, color: COLORS_BASE.YELLOWS[0] },
        { id: 4, min: quants.quan75, max: 1000, text: `> ${quants.quan75}`, color: COLORS_BASE.REDS[0] }
    ]
    const lossesQuants = (quant: number) => (type === 'losses') ? quant / 1000 : quant

    const lineColor = [
        'case',
        ['>=', ['to-number', ['get', `${type}`]], lossesQuants(quants.quan75)],
        `${COLORS_BASE.REDS[0]}`,
        ['>=', ['to-number', ['get', `${type}`]], lossesQuants(quants.quan50)],
        `${COLORS_BASE.YELLOWS[0]}`,
        ['>=', ['to-number', ['get', `${type}`]], lossesQuants(quants.quan25)],
        `${COLORS_BASE.GREENS[1]}`,
        ['>=', ['to-number', ['get', `${type}`]], 0],
        `${COLORS_BASE.GREENS[0]}`,
        `#000`,
    ]
    return { legend: legend, lineColor: lineColor }
}
