import React from 'react'
import { PointStyle } from '../../styles/styles';

type PointProps = { color: string, diameter: string};

function Point({ color, diameter }: PointProps) {
  return (
    <div><PointStyle color={color} diameter={diameter} /></div>
  )
}

export default Point