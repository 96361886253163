import React, { createContext, useState } from 'react';
import { PopupDataType, FeederPopupDataType, PvPopupDataType, TransformerPopupDataType, MapType } from '../types/dataTypes';
import { PopupDataContextType } from '../types/contextTypes'
import { mapGetLayerExists, mapRemoveLayerSource } from '../utils/mapDataHandler';

import { LAYER_IDS } from '../utils/mapConsts';

export const PopupDataContext = createContext<PopupDataContextType | null>(null)

function PopupDataProvider({ children }: any) {
    const nullPopupData: PopupDataType =  {transformer: null, feeder: null, pv: null }
    const [popupData, setPopupData] = useState<PopupDataType>(nullPopupData);

    const updateFeederPopupData = (feederPopupData: FeederPopupDataType) => {
        popupData.feeder = feederPopupData;
        setPopupData({ ...popupData })
    }

    const updatePvPopupData = (pvPopupData: PvPopupDataType) => {
        popupData.pv = pvPopupData;
        setPopupData({ ...popupData })
    }

    const updateTransformerPopupData = (transformerPopupData: TransformerPopupDataType) => {
        popupData.transformer = transformerPopupData;
        setPopupData({ ...popupData })
    }

    const setPopupDataNull = () => {
        setPopupData(nullPopupData);
    }

    const resetAllPopupData = (map: MapType) => {
        if (!map.current) return;
        const { FEEDER_LINE_SELECTED } = LAYER_IDS;
        setPopupDataNull();
        if (mapGetLayerExists(map, 'selectedTransformerLayer')) {
            mapRemoveLayerSource(map, ['selectedTransformerLayer', 'selectedTransformerLayer2'])
            map.current.removeLayer('selectedTransformerLayer');
            map.current.removeLayer('selectedTransformerLayer2');
            map.current.removeSource('selectedTransformer');
        }
        if (map.current.getLayer(FEEDER_LINE_SELECTED)) {
            map.current.removeLayer(FEEDER_LINE_SELECTED);
            map.current.removeSource(FEEDER_LINE_SELECTED);
        }
    }

    return <PopupDataContext.Provider value={{ popupData, 
        updateFeederPopupData, updatePvPopupData, updateTransformerPopupData, 
        setPopupDataNull,
        resetAllPopupData }}>{children}</PopupDataContext.Provider>
};

export default PopupDataProvider;
