import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import Container from '../Shared/Container';
import { CSS } from '../../utils/consts';

type DropdownProps = {
    children: ReactNode,
    containerRef?: React.MutableRefObject<HTMLDivElement> | React.MutableRefObject<null>,
    viewcontainerRef: boolean,
    padding?: string,
    margin?: string,
    left?: string,
    right?: string,
    top?: string,
    height?: string,
    width?: string,
    smallScreen?: boolean,
}

function Dropdown({ containerRef,
    viewcontainerRef = true,
    children,
    padding,
    margin,
    top,
    left,
    right,
    height,
    width,
    smallScreen,
}: DropdownProps) {

    if (!viewcontainerRef) return null;

    return (
        <DropdownContainerStyle>
            <DropdownStyle
                left={left}
                right={right}
                top={top}
                height={height}
                width={width}
                ref={containerRef}
                smallScreen={smallScreen}
            >
                <Container margin={margin} padding={padding} shadow={true} > {children}</Container>
            </DropdownStyle>
        </DropdownContainerStyle>
    )
}

export default Dropdown;

const DropdownStyle = styled.div<{
    padding?: string,
    margin?: string,
    left?: string,
    right?: string,
    top?: string,
    height?: string,
    width?: string,
    smallScreen?: boolean,
}>(
    ({ top = '5px', left = '0', right = '0', height = 'fit-content', width = 'auto', smallScreen = false }) => `
    position: absolute;
    display: inline;
    top: ${top};
    left: ${left};
    right: ${right};
    height: ${height};
    width: ${width};
    ${smallScreen &&
        `@media (max-width: ${CSS.MAX_WIDTH}){
            left: auto;
            right: 0;
        }`}
`)

const DropdownContainerStyle = styled.div<{ zIndex?: string }>(
    ({ zIndex = '100' }) => `
    position: relative;
    z-index: ${zIndex};
`)