import React, { useState, useEffect } from "react";

function useDetectOutsideClick(el: React.MutableRefObject<HTMLDivElement> | React.MutableRefObject<null>, initialState: boolean) {
    const [isActive, setIsActive] = useState(initialState);
    useEffect(() => {
        function pageClickEvent(e: MouseEvent): void {
            const target = e.target;
            if (target instanceof Node) {
                if (el.current !== null && !el.current?.contains(target)) {
                    setIsActive(false);
                }
            }
        };
        window.addEventListener('mousedown', pageClickEvent);

        return () => {
            window.removeEventListener('mousedown', pageClickEvent);
        }
    }, [el]);
    return [isActive, setIsActive] as const;
};

export default useDetectOutsideClick; 