import React from 'react';
import { Text, StyleSheet, View, Font } from '@react-pdf/renderer';

import fontIBMPlexSans from '../../../fonts/IBMPlexSans-Regular.ttf';
import fontIBMPlexSansBold from '../../../fonts/IBMPlexSans-Bold.ttf'

type ScenarioDataRowType = {
  rowHeader: string,
  rowContent: string,
}

function ScenarioDataRow({ rowHeader, rowContent }: ScenarioDataRowType) {
  Font.register({ family: 'IBMPlexSans', src: fontIBMPlexSans });
  Font.register({ family: 'IBMPlexSansBold', src: fontIBMPlexSansBold });

  return (
    <View style={styles.detailsRow}>
      <Text style={styles.header}>{rowHeader}</Text><Text>{rowContent}</Text>
    </View>
  )
}

export default ScenarioDataRow

const styles = StyleSheet.create({
  detailsRow: {
    flexDirection: 'row',
    paddingBottom: '10px',
  },
  header: {
    fontFamily: 'IBMPlexSansBold',
    width: '150px'
  }
});