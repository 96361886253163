import React from 'react';
import { Page, Text, StyleSheet, View, Font, Circle, Svg } from '@react-pdf/renderer';

import { ScenariosType, FeederMetaType, FeederGeodataType } from '../../types/dataTypes';

import fontIBMPlexSans from '../../fonts/IBMPlexSans-Regular.ttf';
import fontIBMPlexSansBold from '../../fonts/IBMPlexSans-Bold.ttf'

import { SCENARIOS, COLORS_BASE } from '../../utils/consts';
import { formatCoords, formatScenarioNames, returnNoData } from '../../utils/dataHandler';

import Footer from './PdfDoc/Footer';
import Title from './PdfDoc/Title';
import ScenarioDataRow from './PdfDoc/ScenarioDataRow';
import SvgMap from './PdfDoc/SvgMap';

type PdfDocPageType = {
  selectedFeederData: FeederMetaType,
  scenario: ScenariosType,
  pageNum: number | undefined,
  feederGeodata: FeederGeodataType,
}

function PdfDocPage({ scenario, selectedFeederData, pageNum, feederGeodata }: PdfDocPageType) {
  Font.register({ family: 'IBMPlexSans', src: fontIBMPlexSans });
  Font.register({ family: 'IBMPlexSansBold', src: fontIBMPlexSansBold });

  return (
    <Page size='A4' orientation='portrait' style={styles.page}>
      <View style={styles.pageContainer}>
        <View>
          <Title selectedFeederData={selectedFeederData} />
          <View style={styles.scenarioData}>
            <Text style={styles.title}>{formatScenarioNames(scenario?.name)} Scenario</Text>
            <View style={styles.details}>
              <View style={styles.leftData}>
                <View style={styles.pvData}>
                  <ScenarioDataRow rowHeader={`Date of simulation`} rowContent={returnNoData(`${scenario?.last_run}`)} />
                  <ScenarioDataRow rowHeader={`Timesteps of simulation`} rowContent={returnNoData(`${scenario?.number_of_timesteps}`)} />
                </View>
                <View style={styles.pvData}>
                  <ScenarioDataRow rowHeader={`Annual losses`} rowContent={returnNoData(`${scenario?.annual_losses} %`)} />
                  <ScenarioDataRow rowHeader={`Substation power`} rowContent={returnNoData(`${scenario?.substation_generation_mw} MW`)} />
                  <ScenarioDataRow rowHeader={`Apparent losses`} rowContent={returnNoData(`${scenario?.losses_sl_tot_mw} MVA`)} />
                  <ScenarioDataRow rowHeader={`Reactive losses`} rowContent={returnNoData(`${scenario?.losses_ql_tot_mw} MVAr`)} />
                </View>
              </View>
              {(scenario?.scenario_type !== SCENARIOS.BASE.TEXT_ID) && <View style={styles.pvData}>
                <ScenarioDataRow rowHeader={`PV plant capacity`} rowContent={returnNoData(`${scenario?.plant_capacity} MW`)} />
                <ScenarioDataRow rowHeader={`Point coordinates`} rowContent={returnNoData(`${formatCoords(scenario?.bus_long, scenario?.bus_lat)}`)} />
                <ScenarioDataRow rowHeader={`Costs`} rowContent={returnNoData(`Rs. ${Math.round(Number(scenario?.construction_costs_pkr))}/-`)} />
                <ScenarioDataRow rowHeader={`Land required`} rowContent={returnNoData(`${scenario?.land_required_ha} ha`)} />
                <ScenarioDataRow rowHeader={`PV generation power`} rowContent={returnNoData(`${scenario?.pv_generation_mw} MW`)} />
                <ScenarioDataRow rowHeader={`Reverse power`} rowContent={returnNoData(`${scenario?.reverse_power} MW`)} />
              </View>}
            </View>
          </View>
          <View style={styles.mapContainer}>
            <SvgMap feederGeodata={feederGeodata} selectedFeederData={selectedFeederData} scenario={scenario} />
          </View>
        </View>
        <View>
          <Text style={styles.title}>Legend</Text>
          <View style={styles.legendPadding}>
            <View style={styles.legendItem}>
              <Text>Transformer</Text>
              <Svg width="10" height="10" viewBox="-10 -20 35 35">
                <Circle cx={10} cy={0} r={10} fill="white" stroke="black" />
              </Svg>
            </View>
            <View style={styles.legendItem}>
              <Text>Substation</Text>
              <Svg width="20" height="20" viewBox="-10 -20 50 50">
                <Circle cx={10} cy={0} r={15} fill={COLORS_BASE.GRAYS[1]} stroke="white" />
              </Svg>
            </View>
            {(scenario?.scenario_type !== SCENARIOS.BASE.TEXT_ID) && <View style={styles.legendItem}>
              <Text>PV location</Text>
              <Svg width="20" height="20" viewBox="-10 -20 50 50">
                <Circle cx={10} cy={0} r={15} fill={COLORS_BASE.GREENS[0]} stroke="white" />
              </Svg>
            </View>}
          </View>
        </View>
        <Footer pageNum={pageNum} />
      </View>
    </Page>
  )
}

export default PdfDocPage

const styles = StyleSheet.create({
  legendPadding: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  legendItem: {
    paddingTop: '5px',
    paddingBottom: '5px',
    flexDirection: 'row',
    fontSize: '12pt',
    fontFamily: 'IBMPlexSans',
  },
  title: {
    fontFamily: 'IBMPlexSansBold',
    fontSize: '18pt'
  },
  page: {
    margin: '50px 40px',
    fontFamily: 'IBMPlexSans',
  },
  pageContainer: {
    justifyContent: 'space-between',
    height: '90vh'
  },
  scenarioData: {
    paddingTop: '30px',
  },
  details: {
    fontSize: '12pt',
    flexDirection: 'row',
  },
  leftData: {
    marginRight: '15px'
  },
  detailsRow: {
    flexDirection: 'row',
  },
  header: {
    fontFamily: 'IBMPlexSansBold',
    width: '200px'
  },
  pvData: {
    marginTop: '14px'
  },
  footer: {
    fontSize: '10pt',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '500px'
  },
  mapContainer: {
    padding: '30px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});