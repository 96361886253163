import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ReactComponent as Logo } from '../../assets/logo.svg';

import { PAGE_TITLE, CSS } from '../../utils/consts';

function AppName() {
  return (
    <Link to="/">
      <AppNameStyle>
        <Logo />
        <TitleSpanStyle>{PAGE_TITLE}</TitleSpanStyle>
      </AppNameStyle>
    </Link>
  )
}

export default AppName;

const AppNameStyle = styled.h1<{}>(() => `
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    > svg {
      padding: 0 10px 0 0;
    }
`)

const TitleSpanStyle = styled.span<{}>(() => `
  @media (max-width: ${CSS.MAX_WIDTH}){
    width: 150px;
    font-size: 12px;
  }
`)