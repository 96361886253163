import React from 'react';
import styled from '@emotion/styled';

import Container from './Container';
import Aside from './Aside';

import { PopupContainerStyle } from '../../styles/styles';

import { ErrorMessageType } from '../../types/dataTypes';

import { COLORS } from '../../utils/consts';

type ErrorProps = {
    errorMessage: ErrorMessageType
}

function Error({ errorMessage }: ErrorProps) {

    return (
        <Aside zIndex={8000} bottom right flex>
            {errorMessage&&<PopupContainerStyle height='auto' >
                <Container height='fit-content' shadow={true} >
                    <ErrorFont>{errorMessage}</ErrorFont>
                </Container>
            </PopupContainerStyle>}
        </Aside>
    )
}

export default Error;

const ErrorFont = styled.p<{}>(()=>`
    color: ${COLORS.FONT.WARNING};
    font-size: small;
`)