import React, { useCallback, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleUp, faGear } from '@fortawesome/free-solid-svg-icons';

import { PopupDataContext } from '../../context/popupDataContext';

import Container from '../Shared/Container';
import RadioOptions from '../Shared/RadioOptions';
import SparkChart from '../Shared/SparkChart';
import { TitleStyle, HrStyle, FontSmallStyle } from '../../styles/styles';
import {getFeederScenarioLineResults} from '../../services/getFeederData.service'
import { getScenarioData, searchParamsObj, getBaselineScenarioData, getOptimumScenarioData, formatPvPopupData } from '../../utils/dataHandler';
import { extractLineScenarioValues } from '../../utils/mapDataHandler';
import { COLORS, SCENARIOS, TEXT, LOSS_VOLTAGE } from '../../utils/consts';
import { LAYER_IDS, PAINT_STYLES } from '../../utils/mapConsts';
import { SelectedPVCapacityType, FeederLinesType, SelectedScenarioLineDataType, SetSelectedPVCapacityType, ScenariosType, AllScenariosType, MapType, FeederMetaType, ManualSelectionModeType, LossesVoltageLegendCombinedType } from '../../types/dataTypes';
import { PopupDataContextType } from '../../types/contextTypes';
import { FieldsetStyle } from '../../styles/styles';
import Button from '../Shared/Button';
import ManualScenarioPv from '../ManualScenario/ManualScenarioPv';

type FeederScenarioSelectProps = {
  selectedScenario: string,
  setSelectedScenario: React.Dispatch<React.SetStateAction<string>>,
  allScenarioData: AllScenariosType,
  viewLossOrVoltage: string,
  selectedFeederData: FeederMetaType,
  map: MapType,
  manualSelectionStage: ManualSelectionModeType,
  setManualSelectionStage: React.Dispatch<React.SetStateAction<ManualSelectionModeType>>,
  selectedPVCapacity: SelectedPVCapacityType,
  setSelectedPVCapacity: SetSelectedPVCapacityType,
  selectedScenarioData: ScenariosType,
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<ScenariosType>>,
  selectedScenarioLineData: SelectedScenarioLineDataType,
  feederLinesGeodata: FeederLinesType,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function FeederScenarioSelect({ map,
  selectedScenario, setSelectedScenario,
  allScenarioData, selectedFeederData, manualSelectionStage, setManualSelectionStage,
  selectedPVCapacity, setSelectedPVCapacity, selectedScenarioData, setSelectedScenarioData, feederLinesGeodata,
  selectedScenarioLineData, viewLossOrVoltage, lossVoltageLegendData }: FeederScenarioSelectProps) {
  const mapCurrent = map.current;
  const baselineScenario: ScenariosType = getBaselineScenarioData(allScenarioData);
  const optimalScenario: ScenariosType = getOptimumScenarioData(allScenarioData);
  const [searchParams] = useSearchParams();
  const selectedFeeder = searchParamsObj(searchParams)?.feeder;
  const MANUAL_REFINE = TEXT.MANUALPV.MANUAL_REFINE;
  const { FEEDER_DETAILED, TRANSFORMERS } = LAYER_IDS
  const {resetAllPopupData, updatePvPopupData} = useContext(PopupDataContext) as PopupDataContextType

  // console.log(selectedScenarioData)

  useEffect(() => () => {
    setSelectedScenario(SCENARIOS.BASE.TEXT_ID)
    setSelectedScenarioData(baselineScenario)
  }, [baselineScenario, setSelectedScenario, setSelectedScenarioData])

  const changeSelectedScenario = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    // remove popup data when we select a different scenario
    resetAllPopupData(map);
    const scenarioIdName = e.currentTarget.value;
    if (!mapCurrent || !allScenarioData || !selectedFeeder || !feederLinesGeodata) { return }
    setSelectedScenario(scenarioIdName);
    setSelectedScenarioData(getScenarioData(allScenarioData, scenarioIdName))
    const scenId = getScenarioData(allScenarioData, scenarioIdName)?.scenario_id;
    if (!scenId && scenId!==0) return;
    if (scenarioIdName === SCENARIOS.MANUAL.TEXT_ID && manualSelectionStage !== 'location-selected') { return }
    updatePvPopupData(formatPvPopupData(getScenarioData(allScenarioData, scenarioIdName)))
    getFeederScenarioLineResults(selectedFeeder, scenId.toString())?.then(
      (res: SelectedScenarioLineDataType) => {
        if (mapCurrent.getLayer(FEEDER_DETAILED)) {
          mapCurrent.removeLayer(FEEDER_DETAILED);
          mapCurrent.removeSource(FEEDER_DETAILED);
        }
        if (!mapCurrent.getSource(FEEDER_DETAILED)) {
          mapCurrent.addSource(FEEDER_DETAILED, {
            "type": "geojson",
            "data": {
              "type": feederLinesGeodata?.type,
              "features": extractLineScenarioValues(feederLinesGeodata, res),
            }
          })
        }
        if (!mapCurrent.getLayer(FEEDER_DETAILED)) {
          mapCurrent.addLayer({
            id: FEEDER_DETAILED,
            source: FEEDER_DETAILED,
            type: 'line',
            paint: {
              "line-color": '#000',
              "line-width": 4,
            },
            layout: {
              visibility: 'visible',
              "line-cap": "round",
            }
          }, TRANSFORMERS);
          viewLossOrVoltage === LOSS_VOLTAGE.LOSS.ID ? mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.losses.lineColor) : mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.voltageDrop.lineColor);
        }
      }).catch((err: any) => console.log(err));

  }, [FEEDER_DETAILED, TRANSFORMERS, allScenarioData, feederLinesGeodata, lossVoltageLegendData.losses.lineColor, lossVoltageLegendData.voltageDrop.lineColor, manualSelectionStage, map, mapCurrent, resetAllPopupData, selectedFeeder, setSelectedScenario, setSelectedScenarioData, updatePvPopupData, viewLossOrVoltage]);

  const selectPVCapacity = useCallback(() => {
    setManualSelectionStage("select-pv-capacity");
  }, [setManualSelectionStage])

  if (!allScenarioData || !baselineScenario || !optimalScenario) return null;

  return (
    <>
      <Container>
        <FieldsetStyle>
          <legend>
            <TitleStyle bold>
              Annual losses
              &nbsp;
              <FontAwesomeIcon icon={faArrowCircleUp} transform={{ rotate: 135 }} />
            </TitleStyle>
          </legend>
          <RadioOptions
            option={SCENARIOS.BASE.TEXT_ID}
            selectedOption={selectedScenario}
            setSelectedOption={changeSelectedScenario}
            boldText={SCENARIOS.BASE.TEXT_ID === selectedScenario}
          >
            <SparkChart name={SCENARIOS.BASE.NAME} baseline={baselineScenario?.annual_losses} value={baselineScenario?.annual_losses} 
            color={COLORS.SCENARIO_SPARK.BASELINE} displaySpark={true} unit={'%'} />
          </RadioOptions>
          <RadioOptions
            option={SCENARIOS.OPTIMAL.TEXT_ID}
            selectedOption={selectedScenario}
            setSelectedOption={changeSelectedScenario}
            boldText={SCENARIOS.OPTIMAL.TEXT_ID === selectedScenario}
          >
            <SparkChart name={SCENARIOS.OPTIMAL.NAME} baseline={baselineScenario?.annual_losses} value={optimalScenario?.annual_losses} 
            color={COLORS.SCENARIO_SPARK.OPTIMAL} displaySpark={true} unit={'%'} />
          </RadioOptions>
          {SCENARIOS.MANUAL.TEXT_ID === selectedScenario &&
            <>
              <RadioOptions option={SCENARIOS.MANUAL.TEXT_ID}
                selectedOption={selectedScenario}
                setSelectedOption={changeSelectedScenario}
                boldText={SCENARIOS.MANUAL.TEXT_ID === selectedScenario}
              >
                <SparkChart name={SCENARIOS.MANUAL.NAME} baseline={baselineScenario?.annual_losses} value={selectedScenarioData?.annual_losses} 
                color={COLORS.SCENARIO_SPARK.MANUAL} displaySpark={(manualSelectionStage !== 'select-location')} unit={'%'}/>
              </RadioOptions>
              {manualSelectionStage === 'select-location' && SCENARIOS.MANUAL.TEXT_ID === selectedScenario &&
                <SelectLocationPvStyle>
                  <b>{TEXT.MANUALPV.SELECT_LOCATION[0]}</b>
                  {TEXT.MANUALPV.SELECT_LOCATION[1]}
                </SelectLocationPvStyle>
              }
            </>
          }
          {/* {(selectedScenario!==SCENARIOS.BASE.ID)&&<ScenarioSelection name={SCENARIOS.MANUAL.NAME} scenario={SCENARIOS.MANUAL.ID} baselineVal={baselineVal} value={value} color={COLORS.SCENARIO_SPARK.MANUAL} selectedScenario={selectedScenario} setSelectedScenario={setSelectedScenario} displaySpark={selectedScenario===SCENARIOS.MANUAL.ID}/>} */}
        </FieldsetStyle>
        {(selectedScenario !== SCENARIOS.BASE.TEXT_ID) && <>
          {(manualSelectionStage !== 'select-location') && <>
            <HrStyle />
            <SparkChart
              name="Loss reduction"
              baseline={baselineScenario?.annual_losses}
              value={
                selectedScenario === SCENARIOS.OPTIMAL.TEXT_ID ?
                  (Number(baselineScenario?.annual_losses) - Number(optimalScenario?.annual_losses)).toString() :
                  (Number(baselineScenario?.annual_losses) - Number(selectedScenarioData?.annual_losses)).toString()
              }
              color={COLORS.SCENARIO_SPARK.LOSS}
              displaySpark={true}
              boldText={true}              
              unit={'%'}
            />
          </>}
          {(manualSelectionStage !== 'select-location') && <>
            <HrStyle />
            <SparkChart
              name="Reverse Power Flow % (of PV gen.)"
              //get the max value of reverse power from all scenarios
              baseline={Math.max(...allScenarioData.map(x => Number(x?.reverse_power))).toString()
              }
              value={
                selectedScenario === SCENARIOS.OPTIMAL.TEXT_ID ? 
                  (Number(optimalScenario?.reverse_power*100/optimalScenario?.pv_generation_mw)).toString() :
                  (Number((selectedScenarioData?.reverse_power ?? 0) * 100 / (selectedScenarioData?.pv_generation_mw ?? 0))).toString()
              }
              color={COLORS.SCENARIO_SPARK.LOSS}
              displaySpark={false}
              boldText={true}
              unit={'%'}
            />
          </>}
          {/* <TooltipWrapper content="Refine search feature not available in demo"> */}
          <RefineSearchStyle>
            <Button onClick={selectPVCapacity}>
              <FontSmallStyle>
                <FontAwesomeIcon icon={faGear} />
                &nbsp;
                {manualSelectionStage === 'select-location' ? MANUAL_REFINE.PV_SIZE : MANUAL_REFINE.SEARCH}
              </FontSmallStyle>
            </Button>
          </RefineSearchStyle>
          {/* </TooltipWrapper> */}
        </>}
      </Container>
      {(manualSelectionStage === 'select-pv-capacity') && selectedFeederData && <ManualScenarioPv
        setManualSelectionStage={setManualSelectionStage}
        selectedFeederData={selectedFeederData}
        setSelectedPVCapacity={setSelectedPVCapacity}
        selectedPVCapacity={selectedPVCapacity}
        setSelectedScenario={setSelectedScenario}
        allScenarioData={allScenarioData}
        setSelectedScenarioData={setSelectedScenarioData}
        selectedScenarioData={selectedScenarioData}
      />}
    </>
  )
}

export default FeederScenarioSelect;

const RefineSearchStyle = styled.div<{}>(() => `
    display: flex;
    justify-content: flex-end;
    padding: 15px 5px 0px 5px;
`)

const SelectLocationPvStyle = styled.p<{}>(() => `
    color: ${COLORS.FONT.ASIDE};
    font-style: italic;
    font-size: 14px;
    margin-left: 18px;
`)