import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';

import FeederSearchBox from './FeederSearchBox';
import FeederInfoBox from './FeederInfoBox';
import FeederScenarioSelect from './FeederScenarioSelect';
import Container from '../Shared/Container';
import Button from '../Shared/Button';
import Dropdown from '../Shared/Dropdown';
import { CSS, COLORS } from '../../utils/consts';
import { FeederMetaType, SelectedScenarioLineDataType, FeederLinesType, AllScenariosType, ScenariosType, MapType, LossesVoltageLegendCombinedType, ManualSelectionModeType, SelectedPVCapacityType, SetSelectedPVCapacityType } from '../../types/dataTypes';

import useIsFeederInParams from '../../hooks/useIsFeederInParams';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

type FeederProps = {
  selectedScenario: string,
  setSelectedScenario: React.Dispatch<React.SetStateAction<string>>,
  viewLossOrVoltage: string,
  setViewLossOrVoltage: React.Dispatch<React.SetStateAction<string>>,
  selectedFeederData: FeederMetaType,
  allScenarioData: AllScenariosType,
  map: MapType,
  manualSelectionStage: ManualSelectionModeType,
  setManualSelectionStage: React.Dispatch<React.SetStateAction<ManualSelectionModeType>>,
  setSelectedPVCapacity: SetSelectedPVCapacityType,
  selectedPVCapacity: SelectedPVCapacityType,
  selectedScenarioData: ScenariosType,
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<ScenariosType>>,
  selectedScenarioLineData: SelectedScenarioLineDataType,
  feederLinesGeodata: FeederLinesType,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function Feeder({
  map,
  selectedScenario, setSelectedScenario,
  selectedFeederData, allScenarioData,
  viewLossOrVoltage,
  manualSelectionStage, setManualSelectionStage,
  setSelectedPVCapacity, selectedPVCapacity,
  selectedScenarioData, setSelectedScenarioData, selectedScenarioLineData, feederLinesGeodata,
  lossVoltageLegendData
}: FeederProps) {

  const isFeederInParams = useIsFeederInParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewFeederInfoBox, setViewFeederInfoBox] = useDetectOutsideClick(containerRef, false)

  return (
    <FeederStyle>
      <div>
        <FeederSearchBox map={map} />
        {isFeederInParams &&
          <>
            <HideBigScreen>
              <Container padding="0px" height="50px" width="50px" margin='10px 0 0 10px'>
                <Button onClick={() => setViewFeederInfoBox(!viewFeederInfoBox)} width="100%">
                  <FontAwesomeIcon icon={faMagnifyingGlassChart} color={COLORS.ICON.MAIN} />
                </Button>
              </Container>
              <Dropdown viewcontainerRef={viewFeederInfoBox}
                top='0'
                left='0px'
                padding='0px'
                right='auto'
                margin='0 0 0 10px'
                width='300px'>
                <FeederInfoBox selectedFeederData={selectedFeederData} />
              </Dropdown>
            </HideBigScreen>
            <HideSmallScreen>
              <FeederInfoBox selectedFeederData={selectedFeederData} />
            </HideSmallScreen>
          </>}
      </div>

      {isFeederInParams && <FeederScenarioSelect
        selectedScenario={selectedScenario}
        setSelectedScenario={setSelectedScenario}
        allScenarioData={allScenarioData}
        viewLossOrVoltage={viewLossOrVoltage}
        selectedFeederData={selectedFeederData}
        map={map}
        manualSelectionStage={manualSelectionStage}
        setManualSelectionStage={setManualSelectionStage}
        selectedPVCapacity={selectedPVCapacity}
        setSelectedPVCapacity={setSelectedPVCapacity}
        selectedScenarioData={selectedScenarioData}
        setSelectedScenarioData={setSelectedScenarioData}
        selectedScenarioLineData={selectedScenarioLineData}
        feederLinesGeodata={feederLinesGeodata}
        lossVoltageLegendData={lossVoltageLegendData}
      />}
    </FeederStyle>
  )
}

export default Feeder;

const FeederStyle = styled.div<{}>(() => `
  width: 300px;
`)

const HideBigScreen = styled.div<{}>(() => `
    @media (min-width: ${CSS.MAX_WIDTH}){
      display: none;
    }
`)

const HideSmallScreen = styled.div<{}>(() => `
    @media (max-width: ${CSS.MAX_WIDTH}){
      display: none;
    }
`)