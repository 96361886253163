export const PAGE_TITLE = "Solar Optimal Feed-in Point Simulation";

export const TEXT = {
    MANUALPV: {
        TITLE: "Select PV capacity for: ",
        CONTENT: `In order to refine the search, please select a 
        PV capacity to observe the simulation of the network effects. 
        The capacity can be edited at any time later.`,
        BUTTON: "Select location",
        BUTTON_SAME_LOCATION: "Update PV only",
        SELECT_LOCATION: [`Select a point on the map`, ` to show loss reduction.`],
        MANUAL_REFINE: { PV_SIZE: "Change PV capacity", SEARCH: "Refine search manually" }
    },
    PDF_REPORT: {
        ADD_SCENARIO: "Add scenario to PDF report",
        TITLE: "PDF report",
        DOWNLOAD: "Download PDF report",
        PDF_DOC: {
            AUTHOR: "Author name",
            KEYWORDS: "grid, losses",
            TITLE: "SOFIP",
            PAGE_SIZE: 'A4',
            PAGE_ORIENTATION: 'portrait'
        }
    }
}

export const COLORS_BASE = {
    BLACKS: ['#000000', '#293744'],
    GRAYS: ['#DFDFDF', '#617C9C', '#555555'], // gray #2 is blueish
    GREENS: ['#35784E', '#B7D388'],
    YELLOWS: ['#F8CF46'],
    REDS: ['#FF3838'],
    PURPLES: ['#9747ff'],
    WHITES: ['#FFFFFF']
}

export const COLORS = {
    FONT: { MAIN: COLORS_BASE.BLACKS[0], SECONDARY: COLORS_BASE.GREENS[0], ASIDE: COLORS_BASE.GRAYS[2], WARNING: COLORS_BASE.REDS[0] },
    ICON: { MAIN: COLORS_BASE.BLACKS[0], SECONDARY: COLORS_BASE.GREENS[0] },
    MAP: { HV_LINE: COLORS_BASE.PURPLES[0], MV_LINE: COLORS_BASE.GREENS[0], TRANSFORMERS: { FILL: '#fff', BORDER: COLORS_BASE.BLACKS[1] } },
    SCENARIO_SPARK: { BASELINE: COLORS_BASE.BLACKS[1], OPTIMAL: COLORS_BASE.GRAYS[1], MANUAL: COLORS_BASE.GREENS[1], LOSS: COLORS_BASE.YELLOWS[0] },
    CHARTS: { LOSS: COLORS_BASE.GREENS[0], VOLTAGE: COLORS_BASE.GRAYS[1], LOAD: COLORS_BASE.GREENS[1] },
    TOOLTIP: { BACKGROUND: COLORS_BASE.BLACKS[1], TEXT: COLORS_BASE.WHITES[0] },
    BUTTON: { MAIN: { BG: COLORS_BASE.BLACKS[1], TEXT: COLORS_BASE.WHITES[0] } }
}

export const SCENARIOS = {
    BASE: { TEXT_ID: 'baseline', NAME: 'Baseline scenario' },
    OPTIMAL: { TEXT_ID: 'optimum', NAME: 'Optimum scenario' },
    MANUAL: { TEXT_ID: 'manual', NAME: 'Manual scenario' }
};

export const LOSS_VOLTAGE = { LOSS: { ID: 'loss', NAME: 'Losses (kW)' }, VOLTAGE: { ID: 'voltage', NAME: 'Voltage drop (%)' } }

export const LOSSES_LEGEND = [
    { id: 1, min: 0.0, max: 0.1, text: '0.0 - 0.1', color: COLORS_BASE.GREENS[0] },
    { id: 2, min: 0.1, max: 0.2, text: '0.1 - 0.2', color: COLORS_BASE.GREENS[1] },
    { id: 3, min: 0.2, max: 0.3, text: '0.2 - 0.3', color: COLORS_BASE.YELLOWS[0] },
    { id: 4, min: 0.3, max: 0.4, text: '> 0.3', color: COLORS_BASE.REDS[0] }]

export const VOLTAGE_LEGEND = [
    { id: 1, min: -5, max: 1, text: '0 - 1', color: COLORS_BASE.GREENS[0] },
    { id: 2, min: 1, max: 2, text: '1 - 2', color: COLORS_BASE.GREENS[1] },
    { id: 3, min: 2, max: 3, text: '2 - 3', color: COLORS_BASE.YELLOWS[0] },
    { id: 4, min: 3, max: 4, text: '> 3', color: COLORS_BASE.REDS[0] }]

export const GLOBAL_SIZES = { HEADER: 50 }

export const CSS = { MAX_WIDTH: "640px" }

export const CHART_THEME = {
    "background": "#ffffff00",
    "textColor": "#333333",
    "fontSize": 11,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#dfdfdf",
                "strokeWidth": 1
            }
        },
        "legend": {
            "text": {
                "fontSize": 12,
                "fill": "#bababa"
            }
        },
        "ticks": {
            "line": {
                "stroke": "#dfdfdf",
                "strokeWidth": 1
            },
            "text": {
                "fontSize": 11,
                "fill": "#bababa"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#dfdfdf",
            "strokeWidth": 0
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 11,
                "fill": "#333333"
            }
        },
        "text": {
            "fontSize": 11,
            "fill": "#333333"
        },
        "ticks": {
            "line": {},
            "text": {
                "fontSize": 10,
                "fill": "#333333"
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 13,
            "fill": "#dfdfdf",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "#dfdfdf",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "#dfdfdf",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "#dfdfdf",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        "container": {
            "background": "#ffffff",
            "color": "#333333",
            "fontSize": 12
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    },
    crosshair: {
        line: {
            strokeWidth: 1,
            stroke: '#dfdfdf',
            strokeOpacity: 1,
        },
    }
}
