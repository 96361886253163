import React, { useEffect, useState } from 'react';
import { Document } from '@react-pdf/renderer';
import { FeederMetaType, PdfDataType, FeederGeodataType} from '../../types/dataTypes';
import { COLORS, CSS, TEXT } from '../../utils/consts';
import PdfDocPage from './PdfDocPage';

type PdfDocProps = {
    pdfData: PdfDataType,
    selectedFeederData: FeederMetaType,
    feederGeodata: FeederGeodataType,
}

function PdfDoc({ pdfData, selectedFeederData, feederGeodata }: PdfDocProps) {
    const DOC = TEXT.PDF_REPORT.PDF_DOC;
    // const [mapUrl, setUrl] = useState('')

    // useEffect(()=>{
    //     if (map.current) {
    //         setUrl(map.current.getCanvas().toDataURL());
    //     } else {
    //         console.log("map not loaded");
    //     }
    // },[map]);
    // console.log(mapUrl)

    return (
        <Document author={DOC.AUTHOR} keywords={DOC.KEYWORDS} title={DOC.TITLE}>
            {pdfData?.map((scenario)=>{
                return <PdfDocPage 
                    key={scenario?.scenario_id} 
                    pageNum={pdfData.indexOf(scenario) + 1}
                    scenario={scenario} 
                    selectedFeederData={selectedFeederData}
                    feederGeodata={feederGeodata}/>
            })}
        </Document>
    )
}

export default PdfDoc