import React, { useCallback, useState, useContext } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { PopupDataContextType } from '../../types/contextTypes';
import { PopupDataContext } from '../../context/popupDataContext';

import Container from '../Shared/Container';
import ButtonClose from '../Shared/ButtonClose';
import Button from '../Shared/Button';
import Slider from '../Shared/Slider';
import { OverlayContainerStyle, TitleStyle, ButtonContainerStyle } from '../../styles/styles';
import { FeederMetaType, ScenariosType, ManualSelectionModeType, AllScenariosType, SelectedPVCapacityType, SetSelectedPVCapacityType } from '../../types/dataTypes';
import { capitalizeWordsInString, formatPvPopupData } from '../../utils/dataHandler';
import { COLORS, SCENARIOS, TEXT } from '../../utils/consts';

type ManualScenarioPvProps = {
  margin?: string,
  left?: string,
  right?: string,
  top?: string,
  textAlign?: string,
  height?: string,
  selectedFeederData: FeederMetaType,
  // setViewPvCapacity: React.Dispatch<React.SetStateAction<boolean>>,
  setManualSelectionStage: React.Dispatch<React.SetStateAction<ManualSelectionModeType>>,
  setSelectedPVCapacity: SetSelectedPVCapacityType,
  selectedPVCapacity: SelectedPVCapacityType,
  setSelectedScenario: React.Dispatch<React.SetStateAction<string>>,
  allScenarioData: AllScenariosType,
  setSelectedScenarioData: React.Dispatch<React.SetStateAction<ScenariosType>>,
  selectedScenarioData: ScenariosType,
}

function ManualScenarioPv({
  // setViewPvCapacity,
  selectedPVCapacity,
  setSelectedPVCapacity,
  margin,
  setManualSelectionStage,
  setSelectedScenario,
  selectedFeederData,
  setSelectedScenarioData,
  selectedScenarioData,
  allScenarioData }: ManualScenarioPvProps) {

  const MIN = 0.5;
  const MAX = 4;
  const DIFF = 0.5;

  const [sizeOnlyUpdate, setSizeOnlyUpdate] = useState<number>(selectedPVCapacity?selectedPVCapacity:MIN);
  const {setPopupDataNull, updatePvPopupData} = useContext(PopupDataContext) as PopupDataContextType

  const onSelectPVCapacity = useCallback((e: any) => {
    e.preventDefault();
    setSelectedPVCapacity(e.target[0].value);
    // setViewPvCapacity(false);
    setManualSelectionStage('select-location');
    setSelectedScenario(SCENARIOS.MANUAL.TEXT_ID);
    setPopupDataNull();
  }, [setManualSelectionStage, setPopupDataNull, setSelectedPVCapacity, setSelectedScenario])

  const onSelectPVCapacityOnly = useCallback(() => {
    // setViewPvCapacity(false);
    setPopupDataNull();
    setManualSelectionStage('location-selected');
    setSelectedScenario(SCENARIOS.MANUAL.TEXT_ID);
    setSelectedPVCapacity(sizeOnlyUpdate);
    const newScenario = allScenarioData?.filter((scenario) =>((scenario?.bus_id === selectedScenarioData?.bus_id) && (Number(scenario?.plant_capacity) === sizeOnlyUpdate)))[0];
    if(newScenario) {
      setSelectedScenarioData(newScenario);
      updatePvPopupData(formatPvPopupData(newScenario))
    }
  }, [allScenarioData, selectedScenarioData?.bus_id, setManualSelectionStage, setPopupDataNull, setSelectedPVCapacity, setSelectedScenario, setSelectedScenarioData, sizeOnlyUpdate, updatePvPopupData]);

  // const pvCapacityOptions = filterObjectByDuplicateProp(allScenarioData, ['plant_capacity']).map((scenario: ScenariosType) => (Number(scenario?.plant_capacity))).filter((el: any) => (el > 0))

  if (!selectedFeederData) return null;

  // const optimalPVCapacityNum: number = Number(parseFloat(optimalPVCapacity)); 
  return (
    <OverlayContainerStyle>
      <Container margin={margin} padding="30px 40px" shadow={true} height={`auto`} maxWidth='600px'>
        <ButtonClose onClick={() => setManualSelectionStage('none')} />
        <TitleStyle>
          <b> {TEXT.MANUALPV.TITLE}
            <u> {capitalizeWordsInString(selectedFeederData.name)} </u>
          </b>
        </TitleStyle>
        <PStyle>{TEXT.MANUALPV.CONTENT}</PStyle>
        <PVCapacityFormStyle onSubmit={onSelectPVCapacity} id="selectPVCapacity">
          <Slider
            name="selectPVCapacity"
            ariaLabel='Change selected PV capacity'
            min={MIN}
            max={MAX}
            diff={DIFF}
            val={selectedPVCapacity?selectedPVCapacity:0.5}
            units=" MW"
            optionalSetState={setSizeOnlyUpdate}
          />
          <ButtonContainerStyle>
            <Button
              type='submit'
              form="selectPVCapacity"
              filledStyle={true}
              value="select-location"
            >
              {TEXT.MANUALPV.BUTTON}
            </Button>
          </ButtonContainerStyle>
          <ButtonPvOnlyStyle>
            <Button
              type='button'
              filledStyle={false}
              buttonStyle='secondary'
              onClick={onSelectPVCapacityOnly}
            >
              <u>
                {TEXT.MANUALPV.BUTTON_SAME_LOCATION}
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </u>
            </Button>
          </ButtonPvOnlyStyle>
        </PVCapacityFormStyle>
      </Container>
    </OverlayContainerStyle>
  )
}

export default ManualScenarioPv

const PStyle = styled.p<{}>(() => `
  color: ${COLORS.FONT.MAIN};
  margin-top: 10px;
`)

const PVCapacityFormStyle = styled.form<{}>(() => `
  position: relative
`)

const ButtonPvOnlyStyle = styled.div<{}>(() => `
  position: absolute;
  right: 0px;
  bottom: 15px;
`)