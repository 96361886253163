import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

import { COLORS } from '../../utils/consts';

import Button from './Button';

type ButtonCloseProps = {
  onClick: () => void,
  height?: string,
  display?: string,
  width?: string
}

function ButtonClose({ onClick, height = "fit-content", width, display }: ButtonCloseProps) {

  return (
    <ButtonCloseStyle display={display} width={width}>
      <Button onClick={onClick} height={height}>
        <FontAwesomeIcon icon={faRectangleXmark} color={COLORS.ICON.SECONDARY} size="xs"/>
      </Button>
    </ButtonCloseStyle>
  )
}

export default ButtonClose;

const ButtonCloseStyle = styled.div<{display?: string, width?: string}>(({display = 'flex', width = "100%"})=>`
  width: ${width};
  display: ${display};
  ${(display==="flex")?`justify-content: flex-end;`:''}
  ${(display==="inline")?`
  position: relative;
  right: 0`:''}
`)
