import React from 'react';
import styled from '@emotion/styled';
import { COLORS_BASE } from '../../utils/consts';

type SkeletonLoaderProps = {
    value: undefined | string
}

function SkeletonLoader({ value }: SkeletonLoaderProps) {
    if(value === undefined) {
        return <SkeletonLoaderStyle />
    } else {
        return <>{value}</>
    }
}

export default SkeletonLoader;

const SkeletonLoaderStyle = styled.div<{}>(() => `
    width:100%;
    height: 15px;  
    animation: skeleton-loading 1s linear infinite alternate;
    /* border-radius: 2px; */
    @ skeleton-loading {
    0% { background-color: ${COLORS_BASE.GRAYS[0]}; }
    100% { background-color:  ${COLORS_BASE.GRAYS[0]}80; }
    }
`)
