export async function baseData(endpoint: string, typeRequest: 'GET' | 'POST', otherHeaders?: object) {
        const settings = {
                method: typeRequest,
                headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        ...otherHeaders
                }
        };
        try {
                const fetchResponse = await fetch(`${process.env.REACT_APP_SOFIP_API}api/${endpoint}`, settings);
                const data = await fetchResponse.json();
                return data;
        } catch (error) {
                return error;
        }
}

export async function getData(endpoint: string,  otherHeaders?: object) {
        return baseData(endpoint, 'GET', otherHeaders);
}

export async function postData(endpoint: string, otherHeaders?: object) {
        return baseData(endpoint, 'POST', otherHeaders);
}

export async function getGeoJson(layer: string) {
        const getGsoJsonString = `${process.env.REACT_APP_GEOSERVER}/sofipdb/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=sofipdb%3A${layer}&outputFormat=application%2Fjson`
        const responseData = await fetch(getGsoJsonString);
        const responseJson = await responseData.json();
        return responseJson;
}
