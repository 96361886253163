import React from "react";
import { useSearchParams } from 'react-router-dom';
import { searchParamsObj } from "../utils/dataHandler";

function useIsFeederInParams() {
    const [ searchParams ] = useSearchParams();
    const selectedFeeder = searchParamsObj(searchParams)?.feeder;
    const isFeederInParams = selectedFeeder?true:false
    return isFeederInParams as boolean;
};

export default useIsFeederInParams; 
