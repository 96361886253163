import React, { useCallback, useContext } from 'react';
import styled from '@emotion/styled';

import { faSolarPanel, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupContainer from '../Shared/PopupContainer';
import Button from '../Shared/Button';
import { COLORS_BASE, TEXT } from '../../utils/consts';
import { CircleStyle, LineStyle, FontSmallStyle } from '../../styles/styles';
import { MapType, SetPdfDataType, PdfDataType, ScenariosType, AllScenariosType } from '../../types/dataTypes';
import { PopupDataContextType } from '../../types/contextTypes';
import { PopupDataContext } from '../../context/popupDataContext';

import { capitalizeWordsInString, resetTransformerData, resetFeederData, formatCoords } from '../../utils/dataHandler';

type InfoBoxesProps = {
  map: MapType,
  pdfData: PdfDataType,
  setPdfData: SetPdfDataType,
  selectedScenario: string,
  allScenarioData: AllScenariosType,
  selectedScenarioData: ScenariosType,

}

function InfoBoxes({ selectedScenarioData, allScenarioData, map, pdfData, setPdfData }: InfoBoxesProps) {
  const padding = "8px 15px";
  const {popupData, updateFeederPopupData, updatePvPopupData, updateTransformerPopupData} = useContext(PopupDataContext) as PopupDataContextType

  const addScenarioToPdf = useCallback(() => {
    const currentScenarioData = allScenarioData?.filter(scenario => scenario?.scenario_id === selectedScenarioData?.scenario_id)
    if(!currentScenarioData || !pdfData) return;
    const newPdfData = pdfData?.concat(currentScenarioData)
    setPdfData(newPdfData)
  }, [allScenarioData, pdfData, selectedScenarioData, setPdfData])

  const resetTransformer = () => {
    resetTransformerData(map);
    updateTransformerPopupData(null);
  }
  
  const resetPv = () => {
    resetFeederData(map);
    updatePvPopupData(null);
  }

  const resetFeeder = () => {
    resetFeederData(map);
    updateFeederPopupData(null);
  }

  return (
    <InfoBoxesStyle>
      {(popupData.transformer !== null) &&
        <PopupContainer
          title={"transformer"}
          icon={<CircleStyle color={'white'} size={'14'} borderColor={COLORS_BASE.GREENS[0]}><CircleStyle color={COLORS_BASE.GREENS[0]} size={'10'} borderColor={'white'} /></CircleStyle>}
          onClick={() => resetTransformer()}
          contents={[
            ["Coordinates", formatCoords(popupData.transformer.geometry.coordinates[0], popupData.transformer.geometry.coordinates[1])],
            ["Transformer capacity", `${popupData.transformer.properties.transformer_size_kva} kVA`],
          ]}
          padding={padding}
        >
        </PopupContainer>}

      {(popupData.feeder !== null) &&
        <PopupContainer
          title={"grid segment"}
          icon={<LineStyle width="15px" color={COLORS_BASE.GREENS[0]} />}
          onClick={() => resetFeeder()}
          contents={[
            ["Length", `${Number(popupData.feeder.properties.len).toFixed(1)} km`],
            ["Losses", `${Number(popupData.feeder.properties.losses * 1000).toFixed(2)} kW`],
            ["Voltage drop", `${Number(popupData.feeder.properties.voltage_drop).toFixed(2)} %`],
            ["Conductor", capitalizeWordsInString(`${popupData.feeder.properties.conductor}`)]
          ]}
          padding={padding}
        >
        </PopupContainer>}

      {(popupData.pv !== null) &&
        <PopupContainer
          title={"feed-in point"}
          icon={<div><CircleStyle color={'white'} size={'18'} borderColor={COLORS_BASE.GREENS[0]}><FontAwesomeIcon icon={faSolarPanel} color={COLORS_BASE.GREENS[0]} transform={{ size: 12 }} /></CircleStyle></div>}
          onClick={() => resetPv()}
          contents={[
            ["Coordinates", formatCoords(popupData.pv.lon, popupData.pv.lat)],
            ["PV plant capacity", `${popupData.pv.capacity} MW`],
            ["Approx. land required", `${popupData.pv.land} ha`],
            ["Construction costs", `${popupData.pv.costs}`]
          ]}
          padding={padding}
        >
          <AddToReportStyle>
            <Button onClick={addScenarioToPdf} disabled={(pdfData?.filter(el => el?.scenario_id === selectedScenarioData?.scenario_id).length !== 0)}>
              <FontSmallStyle color={(pdfData?.filter(el => el?.scenario_id === selectedScenarioData?.scenario_id).length === 0) ? undefined : COLORS_BASE.GRAYS[0]}>
                <FontAwesomeIcon icon={faCirclePlus} />
                &nbsp;
                {TEXT.PDF_REPORT.ADD_SCENARIO}
              </FontSmallStyle>
            </Button>
          </AddToReportStyle>

        </PopupContainer>}
    </InfoBoxesStyle>
  )
}

export default InfoBoxes;

const InfoBoxesStyle = styled.div<{}>(() => `
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`)

const AddToReportStyle = styled.div<{}>(() => `
    display: flex;
    padding: 15px 5px 0px 5px;
`)