import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';

import { ScenariosType } from '../../types/dataTypes';
import { FontSmallStyle } from '../../styles/styles';
import Button from '../Shared/Button';
import { COLORS_BASE, SCENARIOS } from '../../utils/consts';
import { formatCoords, formatScenarioNames } from '../../utils/dataHandler';

type PdfReportListType = {
  scenario: ScenariosType,
  updatePdfData: any,
}

function PdfReportList({ scenario, updatePdfData }: PdfReportListType) {
  const isBaseline = (scenario?.scenario_type===SCENARIOS.BASE.TEXT_ID);
  return (
    <TableTrStyle>
      <td>{formatScenarioNames(scenario?.name)}</td>
      <td>{isBaseline?"No PV installed":formatCoords(scenario?.bus_long, scenario?.bus_lat)}</td>
      <td>
        <Button onClick={() => updatePdfData(scenario)} disabled={isBaseline}>
          <FontSmallStyle color={isBaseline?COLORS_BASE.GRAYS[0]:undefined}>
            <FontAwesomeIcon icon={faCircleMinus} />
          </FontSmallStyle>
        </Button>
      </td>
    </TableTrStyle>
  )
}

export default PdfReportList

const TableTrStyle = styled.tr<{}>(() => `
`)