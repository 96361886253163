import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

import Container from '../Shared/Container';
import Dropdown from '../Shared/Dropdown';
import Button from '../Shared/Button';
import BaseLayerSelect from './BaseLayerSelect';
import { HrStyle } from '../../styles/styles';

import { COLORS, CSS } from '../../utils/consts';
import { BASE_LAYERS, OVERLAY_LAYERS } from '../../utils/mapConsts';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import OverlayLayerToggle from './OverlayLayerToggle';
import { MapType } from '../../types/dataTypes';

type LayersProps = {
  map: MapType,
}

function Layers({ map }: LayersProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewBaseLayerSelect, setViewBaseLayerSelect] = useDetectOutsideClick(containerRef, false)
  const [selectedLayer, setSelectedLayer] = useState(BASE_LAYERS[0].id);

  return (
    <LayersContainer ref={containerRef} >
      <Container padding="0px" height="50px" width="50px" margin='10px 0 0 0'>
        <Button onClick={() => setViewBaseLayerSelect(!viewBaseLayerSelect)} width="100%">
          <FontAwesomeIcon icon={faLayerGroup} color={COLORS.ICON.MAIN} />
        </Button>
      </Container>
      <Dropdown viewcontainerRef={viewBaseLayerSelect}
        top='0'
        left='0px'
        padding='0px'
        right='auto'
        margin='10px 0 0 0'
        width='200px'
        smallScreen={true}>
        <LayersList>
            <OverlayLayerToggle map={map} layer={OVERLAY_LAYERS[0]}/>
            <HrStyle />
          {BASE_LAYERS.map(layer => {
            return (
              <BaseLayerSelect key={BASE_LAYERS.indexOf(layer)} layer={layer} layerArray={BASE_LAYERS} map={map} selectedLayer={selectedLayer} setSelectedLayer={setSelectedLayer} />)
          })}
        </LayersList>
      </Dropdown>
    </LayersContainer>
  )
}

export default Layers;

const LayersList = styled.ul<{}>(() => `
    list-style-type: none;
    margin: 0;
    padding: 10px 20px;
`)

const LayersContainer = styled.div<{}>(()=>`
    @media (max-width: ${CSS.MAX_WIDTH}){
      margin: 60px 10px;
    }
`)
