import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import Container from './Container';
import ButtonClose from './ButtonClose';

import { TitleStyle, PopupContainerStyle } from '../../styles/styles';

type PopupContainerProps = {
    children: ReactNode,
    icon?: ReactNode,
    padding?: string,
    margin?: string,
    left?: string,
    right?: string,
    top?: string,
    textAlign?: string,
    height?: string,
    onClick: () => void,
    title: string,
    contents: any[],
}

function PopupContainer({
    children,
    icon,
    padding,
    margin,
    top,
    textAlign,
    left,
    right,
    height,
    onClick,
    title,
    contents
}: PopupContainerProps) {

    return (
        <PopupContainerStyle left={left} textAlign={textAlign} right={right} top={top} height={height}>
            <Container margin={margin} padding={padding} shadow={true} height={`180px`}>
                <ButtonClose onClick={onClick}/>
                <TitleIconStyle>
                    <TitleStyle>You have selected a <b>{title}</b>&nbsp;&nbsp;&nbsp;</TitleStyle>{icon}
                </TitleIconStyle>
                <table>
                    <tbody>
                        {contents.map((el) => {
                            return <tr key={contents.indexOf(el)}>
                                <ThStyle>{el[0]}</ThStyle>
                                <td>{el[1]}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                {children}
            </Container>
        </PopupContainerStyle>
    )
}

export default PopupContainer;

const TitleIconStyle = styled.div<{}>(() => `
    display: flex;
    align-items: center;
    margin-top: 3px;
`)

const ThStyle = styled.th<{}>(()=>`
    padding-right: 10px;
`)