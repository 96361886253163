import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';

import Error from '../Shared/Error';

import { feederFormat, feederLookup, searchParamsObj } from '../../utils/dataHandler';
// import { mapFilterFeederIdAndScenario } from '../../utils/mapDataHandler';

import { FeedersType, MapType, ErrorMessageType } from '../../types/dataTypes';
import { getSelectedFeederData } from '../../services/getFeederData.service';
import { MAP_PADDING } from '../../utils/mapConsts';


type FeederResultsProps = {
  searchResults: FeedersType[];
  map: MapType;
  highlightedResult: number;
  setHighlightedResult: React.Dispatch<React.SetStateAction<number>>;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setViewResults: React.Dispatch<React.SetStateAction<boolean>>;
  allFeeders: FeedersType[] | null;
}

function FeederResults({ map, allFeeders, searchResults, highlightedResult, setHighlightedResult, setSearchTerm, setViewResults }: FeederResultsProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);
  const mapCurrent = map.current;

  const handleMouseEnter = (feeder: FeedersType) => {
    setHighlightedResult(searchResults.findIndex(el => el === feeder))
  };

  const handleClick = (feeder: FeedersType) => {
    const searchTerm = feederFormat(feeder);
    setSearchTerm(searchTerm);
    const searchedFeeder = feederLookup(allFeeders, searchTerm)
    if (!searchedFeeder) {
      setViewResults(true);
    } else {
      setViewResults(false);
      setSearchParams({ ...searchParamsObj(searchParams), feeder: searchedFeeder === null ? '' : searchedFeeder.idString });
      getSelectedFeederData(searchedFeeder.idString)?.then(
        res => {
          if (res.statusCode === 500) {
            setErrorMessage("500 Error: Feeder data not available")
            return;
          } else {
            if(!mapCurrent) return;
            mapCurrent.fitBounds(
              [res[0].bbox_array[0], res[0].bbox_array[2]], {
              padding: MAP_PADDING
            });
            searchParams.set('feeder', searchedFeeder.idString)
            setSearchParams(searchParams);
          }
        }).catch((err) => console.log(err));
    }
  }

  return (
    <>
      <Error errorMessage={errorMessage} />
      <FeederListStyle>
        {searchResults.sort((a, b) => { return a.name.localeCompare(b.name) }).map((feeder) => {
          return (
            <SingleFeederItem highlight={searchResults[highlightedResult] === feeder}
              key={searchResults.indexOf(feeder)}
              onMouseOver={() => handleMouseEnter(feeder)}
              onClick={() => handleClick(feeder)}
            >
              {feederFormat(feeder)}
            </SingleFeederItem>)
        })}
        {searchResults.length === 0 && <SingleFeederItem highlight={false}>No search results</SingleFeederItem>}
      </FeederListStyle>
    </>
  )
}

export default FeederResults;

const FeederListStyle = styled.ul<{}>(() => `
    list-style-type: none;
    margin: 0;
    padding: 10px;
    max-height: 70vh;
    overflow: auto;
`)

const SingleFeederItem = styled.li<{ highlight: boolean }>(({ highlight }) => `
    font-weight: ${highlight ? 'bold' : 'none'};
    margin: 10px;
    padding: 0;
    &:hover{
      cursor: pointer;
    }
`)