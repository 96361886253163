import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { LOSS_VOLTAGE, SCENARIOS } from '../../utils/consts';
import { LAYER_IDS } from '../../utils/mapConsts';
import { MapType, AllScenariosType, ManualSelectionModeType, LossesVoltageLegendCombinedType } from '../../types/dataTypes';
import { LegendStyle } from '../../styles/styles';
import { searchParamsObj } from '../../utils/dataHandler';

import Line from '../Shared/Line';
import RadioOptions from '../Shared/RadioOptions';

type LossesVoltageProps = {
    selectedScenario: string,
    allScenarioData: AllScenariosType,
    map: MapType,
    viewLossOrVoltage: string,
    setViewLossOrVoltage: React.Dispatch<React.SetStateAction<string>>,
    manualSelectionStage: ManualSelectionModeType,
    lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function LossesVoltage({ map, allScenarioData, selectedScenario, viewLossOrVoltage, setViewLossOrVoltage, manualSelectionStage, lossVoltageLegendData }: LossesVoltageProps) {
    const mapCurrent = map.current;
    const [searchParams] = useSearchParams();
    const selectedFeeder = searchParamsObj(searchParams)?.feeder;
    const changeFeederStyle = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        if (!mapCurrent || !allScenarioData || !selectedFeeder) { return }
        const styleId = e.currentTarget.value;
        setViewLossOrVoltage(styleId);
        if (selectedScenario === SCENARIOS.MANUAL.TEXT_ID && manualSelectionStage !== 'location-selected') { return }
        if (mapCurrent.getLayer(LAYER_IDS.FEEDER_DETAILED)) {
            styleId === LOSS_VOLTAGE.LOSS.ID ? mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.losses.lineColor) : mapCurrent.setPaintProperty(LAYER_IDS.FEEDER_DETAILED, "line-color", lossVoltageLegendData.voltageDrop.lineColor);
        }
    }, [allScenarioData, lossVoltageLegendData.losses.lineColor, lossVoltageLegendData.voltageDrop.lineColor, manualSelectionStage, mapCurrent, selectedFeeder, selectedScenario, setViewLossOrVoltage]);

    return (
        <>
            <RadioOptions
                option={LOSS_VOLTAGE.LOSS.ID}
                selectedOption={viewLossOrVoltage}
                setSelectedOption={changeFeederStyle}
                radioName="losses_voltage"
                name={LOSS_VOLTAGE.LOSS.NAME}
                boldText={LOSS_VOLTAGE.LOSS.ID === viewLossOrVoltage}
            />
            <RadioOptions
                option={LOSS_VOLTAGE.VOLTAGE.ID}
                selectedOption={viewLossOrVoltage}
                setSelectedOption={changeFeederStyle}
                radioName="losses_voltage"
                name={LOSS_VOLTAGE.VOLTAGE.NAME}
                boldText={LOSS_VOLTAGE.VOLTAGE.ID === viewLossOrVoltage}
            />
            {<LossesStyle>
                {(viewLossOrVoltage === LOSS_VOLTAGE.LOSS.ID ? lossVoltageLegendData.losses.legend : lossVoltageLegendData.voltageDrop.legend).map((el) => {
                    return <LegendStyle key={el.id} templateColumn='85px'>
                        <MvLosses color={el.color} text={el.text}/>
                    </LegendStyle>
                })}
            </LossesStyle>}
        </>
    )
}

export default LossesVoltage

const LossesStyle = styled.div<{}>(() => `
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 50% 50%;
`)

type MvLossesProps = { color: string, text: string };
const MvLosses = ({ color, text }: MvLossesProps) => <><Line color={color} thickness="3px" /><p>{text}</p></>
