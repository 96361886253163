import React from 'react';
import styled from '@emotion/styled';
import { COLORS_BASE } from '../../utils/consts';

type InputTextProps = {
    inputId?: string,
    title?: string,
    type?: "text" | "password",
    placeholder?: string | undefined,
    value?: string,
    disabled?: boolean,
    required?: boolean,
    onChange?: (e: any) => void,
    autoComplete?: string
}

function InputText({inputId = 'name', title = 'Name', type = "text", placeholder = undefined, value, disabled=false, required=false, onChange, autoComplete = "off" }: InputTextProps) {

    return (
        <InputTextContainerStyle>
            <LabelStyle htmlFor={inputId}>{title}</LabelStyle>
            <InputTextStyle 
                disabled={disabled} 
                required={required}
                type={type} 
                id={inputId} 
                name={inputId} 
                minLength={4} 
                maxLength={30} 
                placeholder={placeholder} 
                value={value}
                onChange={onChange}
                autoComplete={autoComplete}
            />
        </InputTextContainerStyle>
    )
}

export default InputText;

const InputTextStyle = styled.input<{}>(() => `
    border: 2px solid ${COLORS_BASE.GRAYS[0]};
    border-radius: 3px;
    margin: 5px 2px;
    padding: 5px 7px;
`)

const LabelStyle = styled.label<{}>(() => `
    font-weight: bold;
    font-size: smaller;
    margin: 10px 0 0 0;
`)

const InputTextContainerStyle = styled.div<{}>(()=>`
    display: flex;
    flex-direction: column;
`)

