import React, { useRef } from 'react';
import maplibregl from 'maplibre-gl';
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import { MapType, FeederSubstationType } from '../../../types/dataTypes';
import { mapMarker } from '../../../utils/mapConsts';
import { COLORS_BASE } from '../../../utils/consts';

import useFeederInParams from '../../../hooks/useFeederInParams';

type SubstationLayerProps = {
  map: MapType,
  feederSubstationGeodata: FeederSubstationType,
}

function SubstationLayer({ map, feederSubstationGeodata }: SubstationLayerProps) {
  const mapCurrent = map.current;
  const marker = useRef<maplibregl.Marker | null>(null);
  const feederInParams = useFeederInParams()

  if (marker.current) { marker.current.remove() }
  if (!mapCurrent || !feederSubstationGeodata || !feederInParams) return null;
  const el = document.createElement('div');
  el.innerHTML = mapMarker(faBoltLightning, COLORS_BASE.GRAYS[1], '#fff', 384, 512);
  const selectedSubstation = feederSubstationGeodata?.features[0].geometry.coordinates;
  if (selectedSubstation) { marker.current = new maplibregl.Marker(el).setLngLat([selectedSubstation[0], selectedSubstation[1]]).addTo(mapCurrent);}

  return null;
}

export default SubstationLayer;