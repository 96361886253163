import React, { useRef, useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MapType, FeederLinesType, SelectedScenarioLineDataType, LossesVoltageLegendCombinedType } from '../../../types/dataTypes';
import { PopupDataContextType } from '../../../types/contextTypes';
import { PopupDataContext } from '../../../context/popupDataContext';

import { searchParamsObj } from '../../../utils/dataHandler';
import { mapCursorNormal, mapCursorPointer, mapVisibilityVisible, extractLineScenarioValues } from '../../../utils/mapDataHandler';
import { PAINT_STYLES, LAYER_IDS } from '../../../utils/mapConsts';

type FeederLayerProps = {
  map: MapType,
  feederLinesGeodata: FeederLinesType,
  selectedScenarioLineData: SelectedScenarioLineDataType,
  lossVoltageLegendData: LossesVoltageLegendCombinedType,
}

function FeederLayer({ map, feederLinesGeodata, selectedScenarioLineData, lossVoltageLegendData }: FeederLayerProps) {
  const [searchParams] = useSearchParams();
  const selectedFeeder = searchParamsObj(searchParams)?.feeder;
  const mapCurrent = map.current;
  const handleClickRef = useRef<any>(null);
  const { FEEDER_DETAILED, FEEDER_LINE_SELECTED } = LAYER_IDS;
  const {updateFeederPopupData} = useContext(PopupDataContext) as PopupDataContextType

  const handleClick = useCallback((e: any) => {
    if (!mapCurrent || !e.features) return;
    if (mapCurrent.getLayer(FEEDER_LINE_SELECTED)) {
      mapCurrent.removeLayer(FEEDER_LINE_SELECTED);
      mapCurrent.removeSource(FEEDER_LINE_SELECTED);
    }
    const features = e.features[0];
    const json = features.toJSON();
    console.log(json)
    updateFeederPopupData(json);
    const selectedFeederCoords = json?.geometry.coordinates;
    mapCurrent.addSource(FEEDER_LINE_SELECTED, {
      "type": "geojson",
      "data": {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": selectedFeederCoords
        },
        "properties": {
          "title": "Selected Feeder",
        }
      }
    });
    mapCurrent.addLayer({
      id: FEEDER_LINE_SELECTED,
      source: FEEDER_LINE_SELECTED,
      type: 'line',
      paint: PAINT_STYLES.SELECTED_GRID_SEGMENT,
      layout: {
        visibility: 'none',
        "line-cap": "round",
      }
    }, FEEDER_DETAILED);
    mapVisibilityVisible(map, FEEDER_LINE_SELECTED);
  }, [FEEDER_DETAILED, FEEDER_LINE_SELECTED, map, mapCurrent, updateFeederPopupData])

  if (!mapCurrent || !feederLinesGeodata || !selectedScenarioLineData) return null;
  mapCurrent.off('click', FEEDER_DETAILED, handleClickRef.current)
  if (selectedFeeder) {
    handleClickRef.current = handleClick;
    // mapVisibilityVisible(map, FEEDER_DETAILED)
    mapCurrent.on('click', FEEDER_DETAILED, handleClickRef.current);
    mapCurrent.on('mouseenter', FEEDER_DETAILED, () => { mapCursorPointer(map) });
    mapCurrent.on('mouseleave', FEEDER_DETAILED, () => { mapCursorNormal(map) });

    if (!mapCurrent.getSource(FEEDER_DETAILED)) {

      mapCurrent.addSource(FEEDER_DETAILED, {
        "type": "geojson",
        "data": {
          "type": feederLinesGeodata.type,
          "features": extractLineScenarioValues(feederLinesGeodata, selectedScenarioLineData),
        }
      })

      if (!mapCurrent.getLayer(FEEDER_DETAILED)) {

        mapCurrent.addLayer({
          id: FEEDER_DETAILED,
          source: FEEDER_DETAILED,
          type: 'line',
          paint: {
            "line-color": '#000',
            "line-width": 4,
          },
          layout: {
            visibility: 'visible',
            "line-cap": "round",
          }
        });
        if(mapCurrent.getLayer(LAYER_IDS.TRANSFORMERS)) {mapCurrent.moveLayer(LAYER_IDS.FEEDER_DETAILED, LAYER_IDS.TRANSFORMERS)}

        if(mapCurrent.getLayer(FEEDER_DETAILED)) {
          mapCurrent.setPaintProperty(FEEDER_DETAILED, "line-color", lossVoltageLegendData.losses.lineColor);}
      }
    }
   
  } else {
    mapCurrent.off('click', FEEDER_DETAILED, handleClickRef.current);
    // mapVisibilityNone(map, FEEDER_DETAILED);
    if (mapCurrent.getLayer(FEEDER_DETAILED)) {
      mapCurrent.removeLayer(FEEDER_DETAILED);
      mapCurrent.removeSource(FEEDER_DETAILED)
    }

  };

  return null;
}

export default FeederLayer;