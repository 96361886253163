import React from "react";
import { useSearchParams } from 'react-router-dom';
import { searchParamsObj } from "../utils/dataHandler";

function useFeederInParams() {
    const [ searchParams ] = useSearchParams();
    const selectedFeeder = searchParamsObj(searchParams)?.feeder;
    return selectedFeeder as string;
};

export default useFeederInParams; 