import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

type ContainerProps = {
  children: ReactNode,
  flex?: boolean,
  padding?: string,
  margin?: string,
  height?: string,
  width?: string,
  shadow?: boolean
  color?: string,
  border?: string,
  fontWeight?: string,
  maxWidth?: string,
}

function Container({
  children,
  flex,
  padding,
  height,
  width,
  margin,
  shadow,
  color,
  border,
  fontWeight,
  maxWidth,
}: ContainerProps) {

  return (
    <ContainerStyle
      flex={flex} 
      padding={padding} 
      margin={margin} 
      height={height} 
      width={width} 
      shadow={shadow} 
      color={color} 
      border={border} 
      fontWeight={fontWeight}
      maxWidth={maxWidth}>
      {children}
    </ContainerStyle>
  )
}

export default Container;

const ContainerStyle = styled.div<{ 
  flex?: boolean,
  padding?: string, 
  margin?: string, 
  height?: string, 
  width?: string; 
  shadow?: boolean; 
  color?: string; 
  border?: string;
  fontWeight?: string;
  maxWidth?: string;
 }>(
  ({ flex = false, 
    padding = '15px', 
    height = 'inherit', 
    width = 'auto', 
    margin = '10px', 
    shadow = false, 
    color = "inherit", 
    border = "none",
    fontWeight = "normal",
    maxWidth = "auto" }) => `
  color: ${color};
  font-weight: ${fontWeight};
  background-color: white;
  height:  ${height};
  width:  ${width};
  margin: ${margin};
  padding: ${padding};
  border: ${border};
  border-radius: 3px;
  max-width: ${maxWidth};
  pointer-events: auto;
  ${shadow ? `
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  `: ''}
  ${flex ? `
    display: flex;
    justify-content: space-between;`: ''}
`)