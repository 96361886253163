import React, { ReactNode } from 'react';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';

type PdfLinkProps = {
    children: ReactNode,
    doc: React.ReactElement<ReactPDF.DocumentProps, string | React.JSXElementConstructor<any>>
}

function PdfLink({ children, doc }: PdfLinkProps) {
    return (
        <PDFDownloadLink document={doc} fileName='sofip_download.pdf'>
             {/* {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')} */}
            {children}
        </PDFDownloadLink>
    )
}

export default PdfLink