import React, { useRef, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSolarPanel } from '@fortawesome/free-solid-svg-icons';
import { PopupDataType, SetPopupDataType, MapType, ScenariosType } from '../../../types/dataTypes';
import { PopupDataContextType } from '../../../types/contextTypes';
import { PopupDataContext } from '../../../context/popupDataContext';
import { searchParamsObj, formatPvPopupData } from '../../../utils/dataHandler';

import { mapMarker } from '../../../utils/mapConsts';

import { COLORS, COLORS_BASE } from '../../../utils/consts';

type PVLayerProps = {
  map: MapType,
  selectedScenario: string,
  selectedScenarioData: ScenariosType,
}

function PVLayer({ map, selectedScenario, selectedScenarioData }: PVLayerProps) {
  const [searchParams] = useSearchParams();
  const selectedFeeder = searchParamsObj(searchParams)?.feeder;
  const mapCurrent = map.current;
  const marker = useRef<maplibregl.Marker | null>(null);
  const {updatePvPopupData} = useContext(PopupDataContext) as PopupDataContextType

  let popup: maplibregl.Popup = new maplibregl.Popup({ className: "state-label", closeButton: false, offset: [0, -14] });

  // useEffect(() => {
  //   if (!mapCurrent || !selectedFeeder) return;
  //   return () => {
  //     if(marker.current){
  //       marker.current.remove();
  //     }
  //   };
  // }, [mapCurrent, selectedFeeder])

  if (!mapCurrent || !selectedScenarioData) return null;

  const el = document.createElement('div');
  el.innerHTML = mapMarker(faSolarPanel, 'white', COLORS_BASE.GREENS[0], 640, 512, 30);
  el.className = 'PVmarker';

  if(marker.current){
    marker.current.remove();
  }
  if( !selectedScenarioData?.bus_lat || !selectedScenarioData?.bus_long || !selectedScenarioData?.plant_capacity ) return null;

  if (selectedFeeder && selectedScenario!=='baseline') {
    const coordsTyped: [number, number] = [Number(selectedScenarioData.bus_long), Number(selectedScenarioData.bus_lat)]
    marker.current = new maplibregl.Marker(el).setLngLat(coordsTyped).setPopup(popup.setHTML(`<p>Optimal feed-in point (${selectedScenarioData.plant_capacity} MW)</p>`)).addTo(mapCurrent);
    el.addEventListener('mouseenter', function() { marker.current?.togglePopup() });
    el.addEventListener('mouseleave', function() { marker.current?.togglePopup() });
    el.addEventListener('click', function() { 
      if( popup.isOpen() ){ popup.remove() } 
      updatePvPopupData(formatPvPopupData(selectedScenarioData));
    });
  }
  return null;
}

export default PVLayer;


