import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

import { GLOBAL_SIZES, CSS } from '../../utils/consts';

type AsideProps = {
    children: ReactNode,
    flex?: boolean,
    right?: boolean,
    bottom?: boolean,
    center?: boolean,
    zIndex?: number,
}

function Aside({
    children,
    flex = false,
    right = false,
    bottom = false,
    center = false,
    zIndex = 0 }: AsideProps) {

    return (
        <AsideStyle flex={flex} right={right} bottom={bottom} center={center} zIndex={zIndex}>{children}</AsideStyle>
    )
}

export default Aside;

const AsideStyle = styled.aside<{ flex?: boolean, right?: boolean, bottom?: boolean, center?: boolean, zIndex?: number }>(({ flex, right, bottom, center, zIndex }) => `
    z-index: ${zIndex};
    position: fixed;
    pointer-events: none;
    ${bottom ? `bottom: 20px;` : ``}
    ${!bottom ? `top: ${GLOBAL_SIZES.HEADER + 20}px;` : ``}
    ${(right || center) ? `right: 0;` : ``}
    ${!right ? `left: 0;` : ``}
    ${flex ? `
        display: flex;
        justify-content: space-between;`: ''}
    @media (max-width: ${CSS.MAX_WIDTH}){
        width: 100vw;
    }
  }
`)