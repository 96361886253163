import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

type selectedOptionFunction = ((e: React.FormEvent<HTMLInputElement>) => void);

type OptionSelectionProps = { 
    option: string, 
    selectedOption: string, 
    setSelectedOption: selectedOptionFunction, 
    children?: ReactNode,
    radioName?: string | undefined,
    boldText: boolean,
    name?: string 
};

function RadioOptions({ option, selectedOption, setSelectedOption, radioName, children, boldText, name }: OptionSelectionProps) {
    return (
        <OptionSelectionStyle boldText={boldText}>
            <Input radioName={radioName} option={option} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
            <LabelStyle htmlFor={option} >
                {children?children:name}
            </LabelStyle>
        </OptionSelectionStyle>
    )
}

export default RadioOptions

const OptionSelectionStyle = styled.div<{ boldText?: boolean }>(({ boldText = false }) => `
    display: flex;
    align-items: center;
    margin: 7px 0;
    ${boldText && `font-weight: bold;`}
`)

const LabelStyle = styled.label<{}>(() => `
    width: 100%;
    margin-left: 5px;
    cursor: pointer;
`)

const InputStyle = styled.input<{}>(() => `
  -webkit-appearance: none;
  appearance: none;
  color: #000;
  width: 13px;
  min-width: 13px;
  height: 13px;
  min-height: 13px;
  border: 1.5px solid #000;
  border-radius: 50%;
  position: relative;
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  &::before{
    position: absolute;
    display: block;
    content: "";
    width: 7px;
    min-width: 7px;
    height: 7px;
    min-height: 7px;
    border-radius: 50%;
    transform: scale(0);
    transition: 200ms transform ease-in-out;
    background-color: #000; 
  }
  &:checked::before{
    transform: scale(1);
  }
`)

type InputProps = { option: string, selectedOption: string, setSelectedOption: selectedOptionFunction, radioName: string | undefined }

const Input = ({ option, selectedOption, setSelectedOption, radioName="Option_losses" }: InputProps) =>
    <InputStyle
        type="radio"
        name={radioName}
        value={option}
        id={option}
        onChange={setSelectedOption} 
        checked={selectedOption === option}
    />
