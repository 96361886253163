import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

import { COLORS, COLORS_BASE, CSS } from '../../utils/consts';
import { MAP_BOUNDS, MAP_PADDING } from '../../utils/mapConsts';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import { getFeederNames } from '../../services/getFeederData.service';
import { feederLookup, feederFormat, searchParamsObj, capitalizeWordsInString } from '../../utils/dataHandler';
import { FeedersType, MapType } from '../../types/dataTypes';
import { PopupDataContext } from '../../context/popupDataContext';

import { PopupDataContextType } from '../../types/contextTypes';

import FeederResults from './FeederResults';
import ButtonClose from '../Shared/ButtonClose';
import Container from '../Shared/Container';
import Dropdown from '../Shared/Dropdown';

type FeederSearchBoxProps = {
  map: MapType,
};

function FeederSearchBox({ map }: FeederSearchBoxProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewResults, setViewResults] = useDetectOutsideClick(containerRef, false)
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<null | FeedersType[]>(null);
  const [highlightedResult, setHighlightedResult] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsObj = searchParamsObj(searchParams);
  const paramsFeederId = paramsObj?.feeder;
  const [allFeeders, setAllFeeders] = useState<null | FeedersType[]>(null);
  const { resetAllPopupData } = useContext(PopupDataContext) as PopupDataContextType

  useEffect(() => {
    getFeederNames().then(
      res => {
        setAllFeeders(res);
        if (!paramsFeederId || !res) {
          setSearchTerm('');
        } else {
          const searchTermOnStart = res.filter((feeder: FeedersType) => feeder.id.toString() === paramsFeederId)[0]
          setSearchTerm(`${capitalizeWordsInString(searchTermOnStart.name)} #(${paramsFeederId})`)
        }
      }).catch((err) => console.log(err));
  }, [paramsFeederId]);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>): void => {
    const searchTerm = e.currentTarget.value;
    setSearchTerm(searchTerm);
    if (!searchTerm) {
      setSearchResults(allFeeders);
      setViewResults(true);
      return
    }
    let results = null;
    if (allFeeders) {
      results = allFeeders.filter((el: FeedersType) =>
        (feederFormat(el).toLowerCase().includes(searchTerm?.toLowerCase()))
      );
    }
    //update the list with the filtered states
    setSearchResults(results);
    // if the search term is in the state list or not
    const searchedFeeder = feederLookup(allFeeders, searchTerm)
    if (!searchedFeeder) {
      setViewResults(true);
    } else {
      setViewResults(false);
      setSearchParams({ ...searchParamsObj(searchParams), feeder: searchedFeeder === null ? '' : searchedFeeder.idString })
    }
  }, [allFeeders, searchParams, setSearchParams, setViewResults]);

  const handleSelect = useCallback(() => {
    if (searchTerm) { return }
    setSearchResults(allFeeders);
    setViewResults(true);
    return
  }, [allFeeders, setViewResults, searchTerm]);

  const clearInput = useCallback(() => {
    setViewResults(false);
    setHighlightedResult(0);
    setSearchResults([]);
    let { feeder, ...searchParamsNoFeeder } = searchParamsObj(searchParams);
    setSearchParams(searchParamsNoFeeder);
    setSearchTerm("");
    resetAllPopupData(map);
    if(!map.current) return;
    map.current.fitBounds(MAP_BOUNDS, {
      padding: MAP_PADDING
    });
  }, [map, resetAllPopupData, searchParams, setSearchParams, setViewResults]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (searchResults && viewResults && e.key === "Enter") {
      const searchTerm = searchResults[highlightedResult];
      setSearchTerm(feederFormat(searchTerm))
      const searchedFeeder = feederLookup(allFeeders, feederFormat(searchTerm))
      if (!searchedFeeder) {
        setViewResults(true);
      } else {
        setViewResults(false);
        setSearchParams({ ...searchParamsObj(searchParams), feeder: searchedFeeder === null ? '' : searchedFeeder.idString })
        // mapFilterFeederIdAndScenario(map, searchedFeeder.idString , 0);
      }
    }
    if (viewResults && searchResults && e.key === "ArrowDown") {
      setHighlightedResult(h => h < searchResults.length - 1 ? h + 1 : h)
    }
    else if (viewResults && searchResults && e.key === "ArrowUp") {
      setHighlightedResult(h => h !== 0 ? (h - 1) : h)
    }
    if (!viewResults) {
      setHighlightedResult(0);
    }
  }

  return (
    <FeederSearchBoxContainerStyle>
      <Container height="40px" padding="4px" border={`${paramsFeederId ? `1px solid ${COLORS_BASE.GREENS[0]}` : `1px solid white`}`}>
        &nbsp;&nbsp;
        <FontAwesomeIcon icon={faMagnifyingGlass} color={COLORS.ICON.MAIN} />
        <FeederSearchBoxStyle
          type="text"
          name='Search and select feeder'
          placeholder={'Select feeder ...'}
          value={searchTerm}
          onChange={handleChange}
          onSelect={handleSelect}
          onKeyDown={handleKeyDown}
          autoComplete="off"
        />
        {searchTerm && <ButtonClose onClick={clearInput} display="inline" />}
      </Container>
      <Dropdown
        containerRef={containerRef}
        viewcontainerRef={viewResults}
        top='0'
        left='0'
        padding='0px'
        margin='10px'>
        {searchResults && <FeederResults map={map} allFeeders={allFeeders} setSearchTerm={setSearchTerm} setViewResults={setViewResults} highlightedResult={highlightedResult} setHighlightedResult={setHighlightedResult} searchResults={searchResults} />}
      </Dropdown>
    </FeederSearchBoxContainerStyle>
  )
}

export default FeederSearchBox;

const FeederSearchBoxStyle = styled.input<{}>(() => `
  border-style: none;
  border-width: 0px;
  font-size: 16px;    
  /* height: 100%;   */
  height: calc(100% - 2px);  
  padding: 0px;
  margin: 0 0 0 12px;
  width: calc(100% - 60px);
  &:focus{
    outline: none;
    font-style: italic;
  }
`)

const FeederSearchBoxContainerStyle = styled.div<{}>(() => `
  @media (max-width: ${CSS.MAX_WIDTH}){
    width: 100vw;
    margin-top: 0px;
  }
`)
