import React, { useEffect, useState } from 'react';

import { ErrorMessageType, MapType, TransmissionLinesType } from '../../../types/dataTypes';
import useIsFeederInParams from '../../../hooks/useIsFeederInParams';
import { LAYER_IDS } from '../../../utils/mapConsts';
import { mapVisibilityVisible, mapVisibilityNone } from '../../../utils/mapDataHandler';
import { getTrasnmissionLines } from '../../../services/getFeederData.service';
import { COLORS } from '../../../utils/consts';

type HvLayerProps = {
  map: MapType,
}

function HvLayer({ map }: HvLayerProps) {
  const { TRANSMISSIONSLINES } = LAYER_IDS;
  const mapCurrent = map.current;
  const [transmissionAllData, setTransmissionAllData] = useState<TransmissionLinesType>(null);

  const isFeederInParams = useIsFeederInParams();

  useEffect(() => {
    getTrasnmissionLines().then(
      res => {
        if (res.statusCode === 500) {
          console.log("500 Error: Substation data not available")
        }else{
          console.log("Data is loaded")
          setTransmissionAllData(res)
        }
      }).catch((err) => console.log(err))}, []);

    //add the transmissions layer to the map and add the attributes id and name to the popup
    if (!mapCurrent?.getSource(TRANSMISSIONSLINES) && transmissionAllData) {
      mapCurrent?.addSource(TRANSMISSIONSLINES, {
        "type": "geojson",
        "data": {
          "type": transmissionAllData?.type,
          "features": transmissionAllData?.features,
        },
      });
    }

    if (!mapCurrent?.getLayer(TRANSMISSIONSLINES) && transmissionAllData) {
      mapCurrent?.addLayer({
        id: TRANSMISSIONSLINES,
        type: 'line',
        source: TRANSMISSIONSLINES,
        paint: {
          "line-color": COLORS.MAP.HV_LINE,
          "line-width": 1,
        },
        layout: {
          visibility: 'visible',
        }
      });
    };

  if (!mapCurrent || !mapCurrent.getLayer(TRANSMISSIONSLINES)) return null;
  if (isFeederInParams) {
    mapVisibilityNone(map, TRANSMISSIONSLINES);
  } else {
    mapVisibilityVisible(map, TRANSMISSIONSLINES);
  };

  return null;
}

export default HvLayer;