import React, { useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import Container from '../Shared/Container';
import Button from '../Shared/Button';
import Dropdown from '../Shared/Dropdown';
import PdfLink from './PdfLink';
import PdfDoc from './PdfDoc';
import PdfReportList from './PdfReportList';

import { COLORS, CSS, TEXT } from '../../utils/consts';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

import { FeederGeodataType, PdfDataType, SetPdfDataType, FeederMetaType } from '../../types/dataTypes';

import { TitleStyle, FontSmallStyle } from '../../styles/styles';

type PdfProps = {
    selectedFeederData: FeederMetaType,
    pdfData: PdfDataType,
    setPdfData: SetPdfDataType,
    feederGeodata: FeederGeodataType,
}

function Pdf({ pdfData, setPdfData, selectedFeederData, feederGeodata }: PdfProps) {
    const pdfContainerRef = useRef<HTMLDivElement>(null);
    const [viewPdfReports, setViewPdfReports] = useDetectOutsideClick(pdfContainerRef, false);

    const onOpenPdf = useCallback(() => {
        // e.preventDefault();
        setViewPdfReports(!viewPdfReports);
    }, [setViewPdfReports, viewPdfReports]);

    const updatePdfData = useCallback((scenario: any) => {
        if (!pdfData) return;
        setPdfData(pdfData?.filter((el) => el?.scenario_id !== scenario.scenario_id))
    }, [pdfData, setPdfData])

    return (
        <>
            <PdfContainer ref={pdfContainerRef}>
                <Container padding="0px" height="50px" width="50px" margin='10px 0 0 10px'>
                    <Button onClick={onOpenPdf} width="100%">
                        <FontAwesomeIcon icon={faFilePdf} color={COLORS.ICON.MAIN} />
                    </Button>
                </Container>
                <Dropdown viewcontainerRef={viewPdfReports}
                    top='0'
                    left='10px'
                    padding='0px'
                    right='auto'
                    margin='10px 0 0 0'
                    width='340px'
                    smallScreen={true}>
                    <PdfContainerStyle>
                        <TitleStyle bold>
                            {TEXT.PDF_REPORT.TITLE}
                        </TitleStyle>
                        <TableStyle>
                            <tbody>
                                {pdfData?.map((scenario) => {
                                    return <PdfReportList key={scenario?.scenario_id} scenario={scenario} updatePdfData={updatePdfData} />
                                })}
                            </tbody>
                        </TableStyle>
                        <DownloadReportStyle>
                            <PdfLink doc={<PdfDoc pdfData={pdfData} selectedFeederData={selectedFeederData} feederGeodata={feederGeodata} />}>
                                <FontSmallStyle>
                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                    &nbsp;
                                    {TEXT.PDF_REPORT.DOWNLOAD}
                                </FontSmallStyle>
                            </PdfLink>
                        </DownloadReportStyle>
                    </PdfContainerStyle>
                </Dropdown>
            </PdfContainer>
            {/* <PDFViewStyle>
                <PDFViewer width={"1000px"} height={'1000px'}>
                    <PdfDoc pdfData={pdfData} selectedFeederData={selectedFeederData} feederGeodata={feederGeodata} />
                </PDFViewer>
            </PDFViewStyle> */}
        </>
    )
}

export default Pdf;

// const PDFViewStyle = styled.div<{}>(() => `
//      position: fixed;
//      /* height: 300px; */
//      top: 30px;
//   bottom: 50px;
//   right: 0;
//   height: 50vh;
// `)

const PdfContainer = styled.div<{}>(() => `
    @media (max-width: ${CSS.MAX_WIDTH}){
      display: none;
    }
`)

const PdfContainerStyle = styled.div<{}>(() => `
    padding: 10px 20px;
`)

const TableStyle = styled.table<{}>(() => `
    width: 100%;
`)

const DownloadReportStyle = styled.div<{}>(() => `
    padding: 15px 5px 0px 5px;
`)