import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import lightning_substation from '../../../assets/lightning_substation.png'
import Error from '../../Shared/Error';
import { DissolvedFeedersType, MapType, FeederGeodataType, ErrorMessageType, SubstationsAllType } from '../../../types/dataTypes';
import { capitalizeWordsInString } from '../../../utils/dataHandler';
import { mapCursorNormal, mapCursorPointer, mapVisibilityNone, mapVisibilityVisible } from '../../../utils/mapDataHandler';
import { LAYER_IDS, MAP_PADDING } from '../../../utils/mapConsts';
import useIsFeederInParams from '../../../hooks/useIsFeederInParams';
import { getSelectedFeederData, getSubstationsAll } from '../../../services/getFeederData.service';
import { COLORS } from '../../../utils/consts';

type FeederDissolvedLayerProps = {
  map: MapType,
  setFeederGeodata: React.Dispatch<React.SetStateAction<FeederGeodataType>>,
}

function FeederDissolvedLayer({ map, setFeederGeodata }: FeederDissolvedLayerProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFeederInParams = useIsFeederInParams();
  const { SUBSTATIIONSALL } = LAYER_IDS;
  const mapCurrent = map.current;
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null)
  const [substationAllData, setSubstationAllData] = useState<SubstationsAllType>(null);

  useEffect(() => {
    getSubstationsAll().then(
      res => {
        if (res.statusCode === 500) {
          console.log("500 Error: Substation data not available")
        }else{
          setSubstationAllData(res)
        }
      }).catch((err) => console.log(err))}, []);

  //add the substation layer to the map and add the attributes id and name to the popup
  if (!mapCurrent?.getSource(SUBSTATIIONSALL) && substationAllData) {
    mapCurrent?.addSource(SUBSTATIIONSALL, {
      "type": "geojson",
      "data": {
        "type": substationAllData?.type,
        "features": substationAllData?.features,
      }
    });
  }

  // //TODO add the substation marker to the map
  // //check if the map has the custom marker already if not add it
  // if (!mapCurrent?.hasImage('custom-marker')){
  //   //create the image
  //   const image = new Image(10 , 10);
  //   image.src = lightning_substation;
  //   mapCurrent?.loadImage(image.src, (error, image) => {
  //     if (error) throw error;
  //   })      
  //     mapCurrent?.addImage('custom-marker', image)
  // }
  
  //check if the layer is already added to the map and if the image is already loaded and if the substationsAll 
  //is loaded already add the layer to the map
  if (!mapCurrent?.getLayer(SUBSTATIIONSALL) && substationAllData) {
    mapCurrent?.addLayer({
      id: SUBSTATIIONSALL,
      type: 'circle',
      source: SUBSTATIIONSALL,
      paint: {
        "circle-color": COLORS.MAP.MV_LINE,
        "circle-radius": 5,
        "circle-stroke-color": COLORS.MAP.MV_LINE,
        "circle-stroke-width": 0.5,
      },
      layout: {
        visibility: 'visible',
      }
    });
  };

  let hoveredFeeder: string | undefined = undefined;
  let popup: maplibregl.Popup = new maplibregl.Popup({ className: "state-label", closeButton: false });

  const handleClickDissolved = useCallback((e: maplibregl.MapLayerMouseEvent) => {
    setErrorMessage(null)
    if (!e.features || !mapCurrent) return;
    setFeederGeodata(null)
    const feederProperties = e.features[0].properties;
    const feederId: string | null = feederProperties?.id;
    if (!feederId) return;
    getSelectedFeederData(feederId)?.then(
      res => {
        if (res.statusCode === 500) {
          setErrorMessage("500 Error: Feeder data not available")
          return;
        } else {
          mapCurrent.fitBounds(
            [res[0].bbox_array[0], res[0].bbox_array[2]], {
            padding: MAP_PADDING
          });
          searchParams.set('feeder', feederId)
          setSearchParams(searchParams);
        }
      }).catch((err) => console.log(err));

  }, [mapCurrent, searchParams, setFeederGeodata, setSearchParams]);

  const handleClickDissolvedRef = useRef<any>(null);
  if (!mapCurrent) return null;
  mapCurrent.off('click', SUBSTATIIONSALL, handleClickDissolvedRef.current);
  if (isFeederInParams) {
    mapCurrent.off('click', SUBSTATIIONSALL, handleClickDissolvedRef.current);
    mapVisibilityNone(map, SUBSTATIIONSALL);

  } else {
    handleClickDissolvedRef.current = handleClickDissolved;
    mapCurrent.on('click', SUBSTATIIONSALL, handleClickDissolvedRef.current);
    if (mapCurrent.getLayer(SUBSTATIIONSALL)) mapVisibilityVisible(map, SUBSTATIIONSALL);
    mapCurrent.on('mousemove', SUBSTATIIONSALL, function (e) {
      if (!e.features) return;
      const feederProperties = e.features[0].properties;
      if (e.features.length > 0 && hoveredFeeder !== feederProperties?.id) {
        if (hoveredFeeder) {
          mapCurrent.setFeatureState(
            { source: SUBSTATIIONSALL, id: hoveredFeeder },
            { hover: false }
          );
        }
        hoveredFeeder = feederProperties?.id;
        mapCurrent.setFeatureState(
          { source: SUBSTATIIONSALL, id: hoveredFeeder },
          { hover: true }
        );
        popup.setLngLat(e.lngLat)
          .setHTML(feederLabel({ name: feederProperties?.name, code: feederProperties?.id }))
          .addTo(mapCurrent);
      }
    });
    mapCurrent.on('mouseenter', SUBSTATIIONSALL, () => { mapCursorPointer(map) });
    mapCurrent.on('mouseleave', SUBSTATIIONSALL, function () {
      mapCursorNormal(map);
      if (hoveredFeeder) {
        mapCurrent.setFeatureState(
          { source: SUBSTATIIONSALL, id: hoveredFeeder },
          { hover: false }
        );
        if (popup) { popup.remove() }
      }
      hoveredFeeder = undefined;
    });
  };

  return (
    <Error errorMessage={errorMessage} />
  );
}

export default FeederDissolvedLayer;

const feederLabel = (feeder: DissolvedFeedersType) => {
  return `
  <div id="feeder-label">
      <div>
          <b>Feeder name</b>
          <span>${capitalizeWordsInString(feeder.name)}</span>
      </div>
      <div>
          <b>Feeder code</b>
          <span>${feeder.code}</span>
      </div>
  </div>`}