import React, {ReactNode} from 'react';
import { ButtonStyle } from '../../styles/styles';


type ButtonProps = {
    onClick?: () => void,
    children: ReactNode,
    width?: string,
    height?: string,
    disabled?: boolean,
    filledStyle?: boolean,
    buttonStyle?: 'primary' | 'symbol' | 'secondary', 
    type?: "button" | "submit" | "reset" | undefined,
    form?: string | undefined,
    padding?: string,
    value?: string,
}

function Button({ onClick, children, width, height, disabled = false, filledStyle = false, buttonStyle = 'symbol', type = undefined, form = undefined, padding = '0px', value }: ButtonProps) {

    return (
        <ButtonStyle 
            width={width} 
            height={height} 
            disabled={disabled}
            filledStyle={filledStyle}
            buttonStyle={buttonStyle}
            type={type}
            form={form}
            padding={padding}
            value={value}
            onClick={onClick} 
            >
                {children} 
        </ButtonStyle>
    )
}

export default Button;