import React, { useRef, useCallback } from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faUser, faSignOutAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import Dropdown from '../Shared/Dropdown';
import Button from '../Shared/Button';
import { AuthenticateType, UserType } from '../../types/dataTypes';

import { COLORS, CSS } from '../../utils/consts';
import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

type MenuProps = {
  authenticate: AuthenticateType,
  user: UserType
}

function Menu( { authenticate, user }: MenuProps ) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [viewMenu, setViewMenu] = useDetectOutsideClick(containerRef, false)

  const onLogout = useCallback(() => {
    localStorage.removeItem('access_token');
    return authenticate(null);
  }, [authenticate]);

  return (
    <div ref={containerRef}>
      <Button onClick={() => setViewMenu(!viewMenu)}>
        <FontAwesomeIcon icon={faUser} color={COLORS.ICON.SECONDARY} />
        <SpanStyle>{user}</SpanStyle>
        {viewMenu ?
          <FontAwesomeIcon icon={faChevronUp} color={COLORS.ICON.SECONDARY} /> :
          <FontAwesomeIcon icon={faChevronDown} color={COLORS.ICON.SECONDARY} />}
      </Button>
      <Dropdown viewcontainerRef={viewMenu} padding="10px" margin="10px 0">
        <Button onClick={onLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} color={COLORS.ICON.SECONDARY} />
          <SpanStyle>Log out</SpanStyle>
        </Button>
      </Dropdown>
    </div>
  )
}

export default Menu;

const SpanStyle = styled.span<{}>(() => `
    margin: 0 10px;
    color: ${COLORS.FONT.SECONDARY};
`)