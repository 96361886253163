import React from 'react';
import styled from '@emotion/styled';

type SparkChartProps = { baseline?: string, value?: string, name?: string, color?: string, displaySpark: boolean, boldText?: boolean, unit: string };

function SparkChart({ baseline = '8.2', value = '2', name = "Baseline scenario", color = "black", displaySpark, boldText, unit }: SparkChartProps) {
    const valueNumber = Number(value);
    const valueRounded1 = valueNumber.toFixed(1);
    const valueRounded0 = valueNumber.toFixed(0);
    const valueGTE10 = (valueNumber >= 10);

    return (
        <SparkChartContainer boldText={boldText}>
            <div>
                {name}
            </div>
            <div>
                {displaySpark ?
                    <SubContainer>
                        <SparkChartStyle baseline={baseline} value={value} color={color} />
                        <div>{valueGTE10 ? valueRounded0 : valueRounded1} {unit}</div>
                    </SubContainer>
                    :         
                    <SubContainer>
                    <SparkChartStyle baseline={'100'} value={'0'} color={color} />
                    <div>{valueGTE10 ? valueRounded0 : valueRounded1} {unit}</div>
                    </SubContainer>}
            </div>
        </SparkChartContainer>
    )
}

export default SparkChart;

const SparkChartStyle = styled.div<{ baseline?: string, value?: string, color?: string }>(({ baseline = '8.2', value = '2', color = "black" }) => `
  width: calc((${Number(value) / Number(baseline)}) * 40px);
  height: 10px;
  background-color: ${color};
  display: inline-block;
  margin-right: calc((${(Number(baseline) - Number(value)) / Number(baseline)}) * 40px + 5px);
`)

const SparkChartContainer = styled.div<{ boldText?: boolean }>(({ boldText = false }) => `
    display: flex;
    justify-content: space-between;
    ${boldText && `font-weight: bold;`}
`)
const SubContainer = styled.div<{}>(() => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90px;
`)