import React, { useRef, useState, useEffect } from 'react';
import { useSearchParams, Routes, Route, useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import Feeder from './components/Feeder/Feeder';
import Header from './components/Header/Header';
import Layers from './components/Layers/Layers';
import Legend from './components/Legend/Legend';
import Pdf from './components/Pdf/Pdf';
import Map from './components/Map/Map';
import Aside from './components/Shared/Aside';
import Error from './components/Shared/Error';
import InfoBoxes from './components/InfoBoxes/InfoBoxes';
import Login from './components/Login/Login';
// import Charts from './components/Charts/Charts'

import PopupDataProvider from './context/popupDataContext'

import { AllScenariosType, FeederMetaType, ManualSelectionModeType, LossesVoltageLegendCombinedType, ErrorMessageType, SelectedScenarioLineDataType, PdfDataType, FeederGeodataType, ScenariosType, SelectedPVCapacityType } from './types/dataTypes';

import { searchParamsObj, getBaselineScenarioData, getOptimumScenarioData, createLegendFormat } from './utils/dataHandler';

import { SCENARIOS, LOSS_VOLTAGE, LOSSES_LEGEND, VOLTAGE_LEGEND } from './utils/consts';

import { getSelectedFeederData, getFeederGeodata, getFeederScenarios, getFeederScenarioLineResults } from './services/getFeederData.service';
import { PAINT_STYLES } from './utils/mapConsts';

function App() {
  const map = useRef<maplibregl.Map | null>(null);
  library.add(far, fas);
  const [searchParams] = useSearchParams();
  const paramsFeeder = searchParamsObj(searchParams)?.feeder;
  const [selectedFeederData, setSelectedFeederData] = useState<FeederMetaType>(null);
  const [feederGeodata, setFeederGeodata] = useState<FeederGeodataType>(null)
  const [selectedScenario, setSelectedScenario] = useState(SCENARIOS.BASE.TEXT_ID);
  const [allScenarioData, setAllScenarioData] = useState<AllScenariosType>(null);
  const [selectedScenarioData, setSelectedScenarioData] = useState<ScenariosType>(null);
  const [selectedScenarioLineData, setSelectedScenarioLineData] = useState<SelectedScenarioLineDataType>(null)
  const [viewLossOrVoltage, setViewLossOrVoltage] = useState(LOSS_VOLTAGE.LOSS.ID);
  const [lossVoltageLegendData, setLossVoltageLegendData] = useState<LossesVoltageLegendCombinedType>({losses: {legend: LOSSES_LEGEND, lineColor: PAINT_STYLES.FEEDERS_LOSS["line-color"]}, voltageDrop: {legend: VOLTAGE_LEGEND, lineColor: PAINT_STYLES.FEEDERS_VOLTAGE["line-color"]}})
  const [user, setUser] = useState<string | null>(null);
  const [manualSelectionStage, setManualSelectionStage] = useState<ManualSelectionModeType>('none');
  const [pdfData, setPdfData] = useState<PdfDataType>(null);
  const [selectedPVCapacity, setSelectedPVCapacity] = useState<SelectedPVCapacityType>(undefined);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!paramsFeeder) {
      setSelectedFeederData(null);
      setFeederGeodata(null);
      return;
    }

    getSelectedFeederData(paramsFeeder)?.then(
      res => {
        if (res.statusCode === 500) {
          setErrorMessage("500 Error: Feeder data not available")
        } else {
          setSelectedFeederData(res[0])
        }
      }).catch((err) => console.log(err));

    getFeederGeodata(paramsFeeder)?.then(
      res => {
        if (res.statusCode === 500) {
          setErrorMessage("500 Error: Geo data not available")
        } else {
          setFeederGeodata(res)
        }
      }).catch((err) => console.log(err));

    getFeederScenarios(paramsFeeder)?.then(
      res => {
        if (res.statusCode === 500) {
          setErrorMessage("500 Error: Feeder data not available")
        } 
        if (!res.statusCode) {
          setAllScenarioData(res);
          const baseline: ScenariosType = getBaselineScenarioData(res);
          const optimum: ScenariosType = getOptimumScenarioData(res);
          setPdfData([baseline, optimum]);
          setSelectedScenarioData(baseline);
          setSelectedPVCapacity(optimum?.plant_capacity)
        } else {
          setAllScenarioData(null);
          setPdfData(null);
        }
      }).catch((err) => {
        console.log(err);
        setAllScenarioData(null);
        setPdfData(null);
      })
  }, [paramsFeeder]);

  useEffect(() => {
    if (!selectedFeederData || !selectedScenarioData || !paramsFeeder) return;
    if (!(selectedScenarioData.name === SCENARIOS.BASE.TEXT_ID)) return;
    getFeederScenarioLineResults(paramsFeeder, selectedScenarioData.scenario_id.toString())?.then(
      res => {
        console.log("res in app", res)
        setSelectedScenarioLineData(res)
        if (selectedScenarioData.name === SCENARIOS.BASE.TEXT_ID) {;
          const legend = { 
            losses: createLegendFormat(res.filter((el: any) => Number(el.losses) !== 0).map((el: any) => Number(el.losses)*1000), 'losses'),
            voltageDrop: createLegendFormat(res.map((el: any) => Number(el.voltage_drop)), 'voltage_drop')
          }
          setLossVoltageLegendData(legend);
        }
      }).catch((err) => console.log(err));
  }, [paramsFeeder, selectedFeederData, selectedScenarioData])

  useEffect(() => {
    if (!user) {
      setSelectedFeederData(null);
      setFeederGeodata(null);
      setSelectedScenarioLineData(null);
      if (map.current) {
        map.current = null;
      }
      navigate("/login", { replace: true })
    }
  }, [navigate, user]);

  function authenticate(user: string | null) {
    setUser(user);
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login authenticate={authenticate} />} />
        <Route path="/" element={<>
          <Aside flex zIndex={1000}>
            <Header authenticate={authenticate} user={user} />
          </Aside>
          <Aside bottom zIndex={1000}>
            <Legend
              selectedScenario={selectedScenario}
              allScenarioData={allScenarioData}
              map={map}
              viewLossOrVoltage={viewLossOrVoltage}
              setViewLossOrVoltage={setViewLossOrVoltage}
              manualSelectionStage={manualSelectionStage}
              lossVoltageLegendData={lossVoltageLegendData}
            />
          </Aside>
          <PopupDataProvider>
            <Map
              map={map}
              selectedScenarioData={selectedScenarioData}
              setSelectedScenarioData={setSelectedScenarioData}
              selectedScenario={selectedScenario}
              allScenarioData={allScenarioData}
              selectedFeederData={selectedFeederData}
              manualSelectionStage={manualSelectionStage}
              setManualSelectionStage={setManualSelectionStage}
              setPdfData={setPdfData}
              feederGeodata={feederGeodata}
              setFeederGeodata={setFeederGeodata}
              selectedPVCapacity={selectedPVCapacity}
              selectedScenarioLineData={selectedScenarioLineData}
              viewLossOrVoltage={viewLossOrVoltage}
              lossVoltageLegendData={lossVoltageLegendData}
            />
            <Aside flex zIndex={1000}>
              <Feeder
                map={map}
                selectedScenario={selectedScenario}
                setSelectedScenario={setSelectedScenario}
                selectedFeederData={selectedFeederData}
                allScenarioData={allScenarioData}
                viewLossOrVoltage={viewLossOrVoltage}
                setViewLossOrVoltage={setViewLossOrVoltage}
                manualSelectionStage={manualSelectionStage}
                setManualSelectionStage={setManualSelectionStage}
                selectedPVCapacity={selectedPVCapacity}
                setSelectedPVCapacity={setSelectedPVCapacity}
                selectedScenarioData={selectedScenarioData}
                setSelectedScenarioData={setSelectedScenarioData}
                selectedScenarioLineData={selectedScenarioLineData}
                feederLinesGeodata={feederGeodata?.lines}
                lossVoltageLegendData={lossVoltageLegendData}
              />
              <div style={{ display: 'flex' }}>
                <Layers map={map} />
                {paramsFeeder &&
                  <Pdf
                    selectedFeederData={selectedFeederData}
                    pdfData={pdfData}
                    setPdfData={setPdfData}
                    feederGeodata={feederGeodata}
                  />}
              </div>
            </Aside>
            {paramsFeeder && <Aside bottom right flex zIndex={2000}>
              <InfoBoxes
                map={map}
                pdfData={pdfData}
                selectedScenario={selectedScenario}
                setPdfData={setPdfData}
                allScenarioData={allScenarioData}
                selectedScenarioData={selectedScenarioData}
              />
            </Aside>}
          </PopupDataProvider>
          <Error errorMessage={errorMessage} />
        </>} />
      </Routes>
      {/* <Aside right>
        <Charts/>
      </Aside> */}
    </div>
  );
}

export default App;
