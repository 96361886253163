import styled from '@emotion/styled';
import { COLORS, COLORS_BASE } from "../utils/consts"

export const TitleStyle = styled.h2<{ color?: string, bold?: boolean }>(
  ({ color = COLORS.FONT.SECONDARY, bold = false }) => `
    color: ${color};
    font-weight: ${bold ? 'bold' : 'normal'};
    font-size: 16px;
`)

export const CircleStyle = styled.div<{ color?: string, size?: string, borderColor?: string, borderSize?: string }>(
  ({ color = COLORS.MAP.MV_LINE, size = 10, borderColor = 'white', borderSize = '2px' }) => `
    background-color: ${color};
    height: ${size}px;
    width: ${size}px;
    border-radius: 50%;
    border: ${borderSize} solid ${borderColor};
    // box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
    display: flex;
	  justify-content: center;
	  align-items: center;
`)

export const LineStyle = styled.div<{ color?: string, thickness?: string, width?: string, dashed?: boolean }>(({ color = COLORS.MAP.MV_LINE, thickness = "3px", width = "20px", dashed = false }) => `
  ${dashed ? `background: repeating-linear-gradient(90deg,${color} 0 3px,#0000 0 5px);` : `background-color: ${color};`}
  width: ${width};
  height: ${thickness};
`)

export const PointStyle = styled.div<{ color?: string, diameter?: string}>((
  { color = COLORS.MAP.MV_LINE, diameter = "3px", }) => `
  background-color: ${color};
  width: ${diameter};
  height: ${diameter};
  border-radius: 50%;
`)

export const HrStyle = styled.hr<{}>(() => `
  border-top: 2px dotted ${COLORS_BASE.GRAYS[0]};
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin: 10px 0;
`)

export const FontSmallStyle = styled.span<{ color?: string }>(
  ({ color = 'inherit' }) => `
  font-size: 0.9em;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${color};
`)

export const MapStyle = styled.div<{}>(() => `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`)

export const ButtonStyle = styled.button<{ width?: string, height?: string, filledStyle?: boolean, buttonStyle?: string, padding?: string }>(
  ({ width = 'auto', height = '100%', disabled = false, filledStyle = false, buttonStyle = 'symbol', padding = '0px' }) => `
    font-size: 16px;
    height: ${height};
    width:  ${width};
    padding: ${padding};
    background-color: ${filledStyle ? `${COLORS.BUTTON.MAIN.BG}` : '#ffffff00'};
    font-weight: ${(buttonStyle === 'secondary') ? 'bold' : 'normal'};
    border-style: none;
    padding: ${filledStyle ? '10px 15px' : '0px'};
    margin: ${filledStyle ? '5px' : '0px'};
    border-radius: 3px;
    color: ${filledStyle ? `${COLORS.BUTTON.MAIN.TEXT}` : (buttonStyle === 'secondary') ? `${COLORS.BUTTON.MAIN.BG}` : 'inherit'};
    ${disabled ? 'cursor: not-allowed;' : ''}
    ${(buttonStyle === 'secondary') ? `
        text-decoration-line: underline;
        text-transform: uppercase;
    `: ''}
    ${!disabled ? `:hover{
        cursor: pointer;
    }`: ''}
`)

export const FieldsetStyle = styled.fieldset<{}>(() => `
  border: 0;
  margin: 0;
  padding: 0;  
`)

export const LegendStyle = styled.div<{ templateColumn?: string }>(({ templateColumn = '170px' }) => `
display: grid;
grid-template-columns: 40px ${templateColumn};
justify-content: center;
align-items: center;
p{
  padding: 5px 0;
}
> div{
  display: flex;
  justify-content: center;
}
`)

export const OverlayContainerStyle = styled.div<{}>(() => `
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: ${COLORS.FONT.SECONDARY};
    z-index: 7000;
    pointer-events: none;
    background-color: ${`${COLORS_BASE.BLACKS[1]}60`};
`)

export const ButtonContainerStyle = styled.div<{}>(() => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 10px 0;
    > * {
        margin: 10px;
    }
`)

export const PopupContainerStyle = styled.div<{ padding?: string, margin?: string, left?: string, right?: string, top?: string, height?: string, textAlign?: string, zIndex?: string }>(
  ({ top = '0', left = '0', right = '0', height = 'inherit', textAlign = 'left', zIndex = '2000' }) => `
  position: relative;
  display: inline;
  top: ${top};
  left: ${left};
  right: ${right};
  height: ${height};
  text-align: ${textAlign};
  z-Index: ${zIndex};
  animation: slide-in 1s ease;
  @keyframes slide-in {
      0% { transform: translateY(100%); }
      100% { transform: translateY(0); }
  }    
`)
